import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from "../../../libs/axios";

const TermsAdmin = () => {
    const [content, setContent] = useState('');
    const [originalContent, setOriginalContent] = useState('');
    const [isChanged, setIsChanged] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    const handleEdit = () => {
        setIsEditing(true);
    }

    useEffect(() => {
        // Fetch original content from server
        axios.get('/content/terms')
            .then(res => {
                setContent(res.data); // Assuming res.data is HTML content like "<p>asssdas12</p>"
                setOriginalContent(res.data); // Set originalContent once fetched
            })
            .catch(err => {
                console.error('Error fetching content:', err);
            });
    }, []);

    const handleContentChange = (value) => {
        setContent(value);
        setIsChanged(value !== originalContent); // Compare current value with originalContent
    }

    const handleSave = () => {

        axios.post('/content/terms', { terms: content })
            .then(res => {
                console.log('Content saved successfully:', res.data);
                // Update original content to the new saved content
                setOriginalContent(content);
                setIsChanged(false); // Reset isChanged after saving
                setIsEditing(false); // Exit edit mode after saving
            })
            .catch(err => {
                console.error('Error saving content:', err);
            });
    }


    return (
        <div className='flex flex-col px-2 h-fit box-border sm:px-10 gap-5'>
            <h1 className='text-3xl text-center mt-4'>ჩვენს შესახებ: </h1>
            <div className='card p-5 shadow-xl w-full bg-white h-fit box-border'>
                <h1 className='text-2xl ml-4 mt-2'>კონტენტი:</h1>
                <div className='divider' />
                <div className='relative overflow-y-auto h-[65dvh] max-h-[65dvh]'>
                    {isEditing ? (
                        <ReactQuill
                            value={content}
                            onChange={handleContentChange}
                            modules={quillModules}
                            formats={quillFormats}
                            className="h-[57dvh]"
                        />
                    ) : (
                        <div dangerouslySetInnerHTML={{ __html: content }} />
                    )}
                </div>
            </div>
            {!isEditing && (
                <button
                    className="bg-blue-500 btn text-white rounded-lg mb-5"
                    onClick={handleEdit}
                >
                    Edit
                </button>
            )}
            {isEditing && (
                <button
                    className={`bg-green-500 btn text-white rounded-lg mb-5 ${!isChanged && 'opacity-50 cursor-not-allowed'}`}
                    onClick={handleSave}
                    disabled={!isChanged}
                >
                    შენახვა
                </button>
            )}
        </div>
    )
}

const quillModules = {
    toolbar: [
        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'align': [] }, { 'color': [] }, { 'background': [] }],
        ['link', 'image', 'video'],
        ['clean']
    ],
}

const quillFormats = [
    'header', 'font',
    'list', 'bullet',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'align', 'color', 'background',
    'link', 'image', 'video'
]

export default TermsAdmin;
