import React, { useRef } from 'react'
import axios from '../../../../libs/axios'

export default function UploadVideo({ onUpload }) {
    const inputFileRef = useRef(null);

    const handleChangeFile = async (e) => {
        try {
            const formData = new FormData();
            const file = e.target.files[0]
            formData.append('video', file);
            const { data } = await axios.post(`/content/video`, formData);
            console.log(data);
            const newVideoUrl = `${process.env.REACT_APP_EXPRESS_URL}/uploads/content/mainvideo.mp4?${new Date().getTime()}`;
            onUpload(newVideoUrl);
        } catch (err) {
            console.warn(err);
            alert('You can\'t upload video');
        }
    }

    return (
        <div onClick={() => inputFileRef.current.click()}>
            <input ref={inputFileRef} hidden onChange={handleChangeFile} type='file' accept='video/mp4' />
            <button className="btn">ვიდეოს ატვირთვა</button>
        </div>
    )
}
