import 'leaflet/dist/leaflet.css';
import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import customMarkerIcon from '../../assets/icons/map-pin.svg';

const center = [41.69736371539678, 42.16411372292937];
const locations = [
  { name: "კინტრიშის ეროვნული პარკი", position: [41.74996896243134, 42.037738623229544] },
  { name: "ღორჯომი", position: [41.718567641025764, 42.38798734217733] },
  { name: "შუამთის ტბები", position: [41.572327326791076, 42.54120226370852] },
  { name: "ბათუმი", position: [41.64725151803047, 41.64399139209973] },
  { name: "ხიხანის ციხე", position: [41.54185876677197, 42.5655575772028] },
  { name: "ისპანის ტორფნარი", position: [41.86304140559905, 41.7984805679034] },
  { name: "მაჭახელას ეროვნული პარკი", position: [41.46833218796753, 41.842076617086356] },
  { name: "უჩხოს კანიონი", position: [41.686398006836576, 42.311506864474964] },
  { name: "პეტრას ციხე", position: [41.768173198132146, 41.75431116740465] },
  { name: "ჭვანას ხეობა", position: [41.67261976513869, 42.15293298544539] },
  { name: "ჩირუხი", position: [41.4693638984931, 42.46548809925103] },
  { name: "მირვეთის ჩანჩქერი", position: [41.5307562454836, 41.7122179808907] },
  { name: "მტირალას ეროვნული პარკი", position: [41.66180031644051, 41.878395194391096] },
  { name: "სარფი", position: [41.52273982172047, 41.5478574220001] },
  { name: "ბეშუმი", position: [41.61203737899164, 42.55553996060649] },
  { name: "მერისი", position: [41.58803427681779, 42.0098624101106] },
  { name: "ბათუმის ბოტანიკური ბაღი", position: [41.695197686451145, 41.70956561194637] },
  { name: "ქობულეთი", position: [41.815975913039956, 41.779676222957846] },
  { name: "თაგო", position: [41.62641169091753, 42.31499749972535] },
  { name: "ციხისძირი", position: [41.7694540818901, 41.76641466176035] },
  { name: "კაპნისთავის კანიონი", position: [41.56101488397342, 41.742180948771185] },
  { name: "კვარიათი", position: [41.54449589642908, 41.56436136063876] },
  { name: "გონიო", position: [41.56071476951404, 41.571618874083] },
  { name: "ხაბელაშვილების ხიდი", position: [41.70568045996385, 42.1804010152648] },
];

const customIcon = L.icon({
  iconUrl: customMarkerIcon,
  iconSize: [30, 30], // Size of the icon
  iconAnchor: [15, 30], // Point of the icon which will correspond to marker's location
  popupAnchor: [0, -30], // Point from which the popup should open relative to the iconAnchor
});

const Map = () => {
  return (
    <MapContainer center={center} zoom={10} scrollWheelZoom={false}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {locations.map((loc, _index) => (
        <Marker icon={customIcon} key={_index} position={loc.position}>
          <Popup>
            {loc.name}
          </Popup>
        </Marker>
      ))}
    </MapContainer>
  );
};


export default Map;
