import React, { useEffect, useState } from 'react';
import axios from '../../../../libs/axios';
import ExportToZip from './exports/ExportToZip';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import supabase from '../../../../libs/supabase';
import ImageViewer from 'react-simple-image-viewer';
import { getYearOnly } from '../../../../utils/getYearOnly';
import ExportScreens from './exports/ExportScreens';
import ExportReaches from './exports/ExportReaches';

const ScreensArchive = () => {
    const [currentData, setCurrentData] = useState([]);
    const [archivedData, setArchivedData] = useState([]);
    const [activeUser, setActiveUser] = useState(null);
    const [activeTab, setActiveTab] = useState(0);
    const [oldData, setOldData] = useState([]);
    const [years, setYears] = useState([]);

    useEffect(() => {
        const fetchOldScreens = async () => {
            const { data, error } = await supabase
                .from('screens')
                .select("*")
            if (error) {
                console.error('Error fetching winners:', error);
            } else {
                setOldData(data);
                console.log(oldData)
            }
        }
        fetchOldScreens()
    }, [oldData])

    useEffect(() => {
        axios.get('/archive')
            .then(res => setYears(res.data))
            .catch(err => console.error(err));

        axios.get('/screen/all')
            .then(res => setCurrentData(res.data))
            .catch(err => console.error(err));
    }, []);

    const getArchivedScreens = async (year) => {
        try {
            const response = await axios.get(`/archive/screens?date=${year}`);
            setArchivedData(response.data);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        if (activeTab !== 0 && activeTab !== 2023) {
            getArchivedScreens(activeTab);
        }
    }, [activeTab]);

    const handleAccordionClick = (user) => {
        setActiveUser(activeUser === user ? null : user);
    };

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    return (
        <div className="px-5 box-border w-full">
            <Tabs>
                <TabList className="flex border-b mb-4">
                    <Tab className={`py-2 px-4 cursor-pointer ${activeTab === 0 ? '!bg-purple-500' : 'bg-gray-100'} hover:bg-gray-100`} onClick={() => handleTabClick(0)}>
                        ახლა
                    </Tab>
                    {years?.map((year, _index) => {
                        if (getYearOnly(year.date) === 2023) return null;
                        return (
                            <Tab key={_index} className={`py-2 px-4 cursor-pointer ${activeTab === getYearOnly(year.date) ? '!bg-purple-500' : 'bg-gray-100'} hover:bg-gray-100`} onClick={() => handleTabClick(getYearOnly(year.date))}>
                                {getYearOnly(year.date)}
                            </Tab>
                        )
                    })}
                    <Tab className={`py-2 px-4 cursor-pointer ${activeTab === 2023 ? '!bg-purple-500' : 'bg-gray-100'} hover:bg-gray-100`} onClick={() => handleTabClick(2023)}>
                        2023
                    </Tab>
                </TabList>

                <TabPanel>
                    <ExportToZip jsonData={currentData} fileName="screens" className='mb-5' />
                    <ExportReaches data={currentData} fileName="views_data.xlsx" />
                    {/* adasdad */}
                    {currentData.length > 0 ? (
                        currentData.map((user) => (
                            <UserAccordion key={user._id} user={user} isActive={activeUser === user} onAccordionClick={handleAccordionClick} />
                        ))
                    ) : (
                        <p>სქრინები არ არის...</p>
                    )}
                </TabPanel>

                {years?.map((year, _index) => {
                    if (getYearOnly(year.date) === 2023) return null;
                    return (
                        <TabPanel key={_index}>
                            <ExportToZip jsonData={archivedData} fileName="screens" className='mb-5' />
                            {archivedData.length > 0 ? (
                                archivedData.map((user) => (
                                    <UserAccordion key={user._id} user={user} isActive={activeUser === user} onAccordionClick={handleAccordionClick} />
                                ))
                            ) : (
                                <p>სქრინები არ არის...</p>
                            )}
                        </TabPanel>
                    )
                })}

                <TabPanel>
                    <div className='flex flex-wrap justify-center gap-5'>
                        <ExportScreens jsonData={oldData} fileName="screens" className='mb-5' />
                        {oldData?.map((screen, _index) => (
                            <img className='w-[250px] h-[250px] object-cover' src={`https://qncbnxbxcvvacstnmmdk.supabase.co/storage/v1/object/public/screens/${screen.image}`} alt='' />
                        ))}
                    </div>
                </TabPanel>

            </Tabs>
        </div>
    );
};

const UserAccordion = ({ user, isActive, onAccordionClick }) => {
    const [activeTab, setActiveTab] = useState('stage_1');
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    return (
        <div className={`border rounded-lg overflow-hidden mb-4 w-full ${isActive ? 'border-gray-300' : ''}`}>
            <div
                className={`accordion-header cursor-pointer bg-gray-200 py-3 px-4 border-b ${isActive ? 'rounded-t-lg' : ''}`}
                onClick={() => onAccordionClick(user)}
            >
                <span className="text-lg font-medium">{user.name} {user.lastname} --- {user.personalNumber}</span>
            </div>
            {isActive && (
                <div className="accordion-body bg-white py-4 px-4 w-full">
                    <Tabs>
                        <TabList className="flex border-b w-full">
                            <Tab
                                className={`py-2 px-4 w-full cursor-pointer ${activeTab === 'stage_1' ? '!bg-gray-200' : '!bg-gray-100'} hover:bg-gray-100`}
                                onClick={() => handleTabClick('stage_1')}
                            >
                                ეტაპი 1
                            </Tab>
                            <Tab
                                className={`py-2 px-4 w-full cursor-pointer ${activeTab === 'stage_2' ? '!bg-gray-200' : '!bg-gray-100'} hover:bg-gray-100`}
                                onClick={() => handleTabClick('stage_2')}
                            >
                                ეტაპი 2
                            </Tab>
                        </TabList>
                        <TabPanel>
                            <LazyLoadScreens screens={user.screens.filter(screen => screen.stage === 1)} />
                        </TabPanel>
                        <TabPanel>
                            <LazyLoadScreens screens={user.screens.filter(screen => screen.stage === 2)} />
                        </TabPanel>
                    </Tabs>
                </div>
            )}
        </div>
    )
};

const LazyLoadScreens = ({ screens }) => {
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);

    const openImageViewer = (index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    };

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };

    return (
        <div className="w-full flex flex-wrap bg-gray-100 p-4">
            {screens.length > 0 ? (
                screens.map((screen, index) => (
                    <div
                        key={screen._id}
                        className="screen w-1/3 p-2 cursor-pointer"
                        onClick={() => openImageViewer(index)}
                    >
                        რიჩები : {screen.views}
                        <img
                            src={`${process.env.REACT_APP_EXPRESS_URL}${screen.image}`}
                            alt={`Screen ${screen._id}`}
                            className="rounded-lg shadow-md object-cover w-full h-40"
                        />
                    </div>
                ))
            ) : (
                <p>სქრინები არ არის...</p>
            )}
            {isViewerOpen && (
                <ImageViewer
                    src={screens.map(screen => `${process.env.REACT_APP_EXPRESS_URL}${screen.image}`)}
                    currentIndex={currentImage}
                    disableScroll={true}
                    closeOnClickOutside={true}
                    onClose={closeImageViewer}
                />
            )}
        </div>
    );
};

export default ScreensArchive;
