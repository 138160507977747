import React, { useEffect } from 'react'
import ResetPassForm from '../../components/Forms/ResetPassForm'
import { useQuery } from "../../utils/useQuery"
import { useNavigate, useParams } from 'react-router-dom';

const VerifyReset = () => {
  const query = useQuery();
  const token = query.get('token');
  const navigate = useNavigate()
  const { role } = useParams();
  useEffect(() => {
    if (!role && !token) navigate("/")
  }, [role, token, navigate])
  return (
    <div className="w-full md:w-2/3 mx-auto p-6 h-[80dvh]">
      <div className="card w-full lg:w-1/2 bg-base-100 shadow-xl mx-auto mt-6">
        <div className="card-body">
          <ResetPassForm token={token} role={role} />
        </div>
      </div>
    </div>
  )
}

export default VerifyReset