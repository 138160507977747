import { Tab, TabList, TabPanel, Tabs as ReactTabs } from 'react-tabs';
// import UpsertProfileForm from '../Auth/UpsertProfileForm';
import TourPackage from '../TabSingles/TourPackage/Index';
import SocialsStatistics from '../TabSingles/ReachStatistics/Index';
import EditDetails from '../TabSingles/EditDetails/Index';
import useCurrentUser from '../../../hooks/useCurrentUser';
import UpdatePassword from '../TabSingles/updatePass/UpdatePassword';
// import SocialScreens from '../SocialScreens';
// import UpdatePasswordForm from '../Auth/UpdatePasswordForm';

const Tabs = ({ data, userId }) => {
    const { user } = useCurrentUser();
    function convertToDateInputFormat(dateString) {
        // Create a new Date object from the provided string
        const date = new Date(dateString);

        // Extract the year, month, and day components
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(date.getUTCDate()).padStart(2, '0');

        // Return the date in the format YYYY-MM-DD
        return `${year}-${month}-${day}`;
    }
    return (
        <ReactTabs
            className=""
            selectedTabClassName='tab-active'>

            <TabList>
                <Tab className="tab tab-lg tab-lifted font-fira-go">პაკეტი</Tab>
                <Tab className="tab tab-lg tab-lifted font-fira-go">სქრინები</Tab>
                <Tab className="tab tab-lg tab-lifted font-fira-go">პროფილის რედაქტირება</Tab>
                <Tab className="tab tab-lg tab-lifted font-fira-go">პაროლის შეცვლა</Tab>
            </TabList>
            <TabPanel>
                <TourPackage tour={user?.tour} />
            </TabPanel>
            <TabPanel>
                <SocialsStatistics userId={userId} hasReached={user?.stage === 2} />
                {/* <SocialScreens userId={userId} hasReached={true} /> */}
            </TabPanel>
            <TabPanel>
                <EditDetails defaults={{ ...data, tk: data.social?.tk, fb: data.social?.fb, ig: data.social?.ig, yt: data.social?.yt, birthday: convertToDateInputFormat(data.birthday) }} />
                {/* <UpsertProfileForm defaults={{ ...data }} /> */}
            </TabPanel>
            <TabPanel>
                <UpdatePassword />
                {/* <UpdatePasswordForm userEmail={userEmail} /> */}
            </TabPanel>
        </ReactTabs>
    );
};

export default Tabs;
