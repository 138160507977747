import React, { useState } from 'react';
import Instagram from './Instagram';
import Tiktok from './Tiktok';
import Facebook from './Facebook';

const SocialFeeds = () => {
    const [activeIndex, setActiveIndex] = useState(0);

    return (
        <div className="my-20">
            <h1 className="font-medium text-xl sm:text-2xl md:text-2xl lg:text-2xl p-1 ml-10 text-gray-900">
                კამპანიის ფარგლებში ბოლოს დამატებული პოსტები
            </h1>
            <div className="divider"></div>
            <div className="tabs ml-10 w-fit">
                <button className={`tab tab-lg ${activeIndex === 0 ? 'tab-active' : ''}`} onClick={() => setActiveIndex(0)}>Tiktok</button>
                <button className={`tab tab-lg ${activeIndex === 1 ? 'tab-active' : ''}`} onClick={() => setActiveIndex(1)}>Instagram</button>
                <button className={`tab tab-lg ${activeIndex === 2 ? 'tab-active' : ''}`} onClick={() => setActiveIndex(2)}>Facebook</button>
            </div>
            <div className='w-full flex justify-center'>
                <Tabs activeIndex={activeIndex} />
            </div>
        </div>
    );
};

const Tabs = ({ activeIndex }) => (
    <div className='w-[90%] lg:w-[80%]'>
        {activeIndex === 0 && <Tiktok />}
        {activeIndex === 1 && <Instagram />}
        {activeIndex === 2 && <Facebook />}
    </div>
);

export default SocialFeeds;
