import axios from "../libs/axios";

export const removeFile = ({ url, reset }) => {
  axios
    .post("/file/remove", {
      file: url,
    })
    .then((res) => {
      console.log(res);
      reset();
    })
    .catch((err) => {
      console.log(err);
    });
};
