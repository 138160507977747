import React, { useEffect, useState } from 'react'
import useCurrentUser from '../../hooks/useCurrentUser'
import EmailSend from '../../components/EmailVerification/EmailSend';
import EmailConfirm from '../../components/EmailVerification/EmailConfirm';
import { useNavigate } from 'react-router-dom';
// import { ReactCodeInput } from 'react-code-input'

const Step = {
  send: 'send',
  confirm: 'confirm',
};

export default function EmailVerify() {
  const [step, setStep] = useState(Step.send);
  const navigate = useNavigate();
  const { user } = useCurrentUser();


  useEffect(() => {
    if (user?.role === "admin") return navigate('/admin')
    if (!user?.email) return navigate('/')
  }, [user?.role, user?.email, navigate])

  if (step === Step.send) return <EmailSend role={user.role} nextStep={() => setStep(Step.confirm)} email={user?.email} />
  if (step === Step.confirm) return <EmailConfirm role={user.role} email={user?.email} />
}
