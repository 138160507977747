import React from 'react';
import * as XLSX from 'xlsx';

const ExportReaches = ({ data, fileName = 'data.xlsx' }) => {
    const exportToExcel = () => {
        // Flatten the data into a readable format
        const flattenedData = [];

        data?.forEach(user => {
            if (Array.isArray(user?.screens)) {
                user.screens.forEach(screen => {
                    flattenedData.push({
                        name: user.name,
                        lastname: user.lastname,
                        stage: screen.stage,
                        views: screen.views
                    });
                });
            } else {
                flattenedData.push({
                    name: user.name,
                    lastname: user.lastname,
                    stage: '',
                    views: ''
                });
            }
        });

        // Create a new worksheet
        const ws = XLSX.utils.json_to_sheet(flattenedData);

        // Create a new workbook
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Users');

        // Save the workbook as a file
        XLSX.writeFile(wb, fileName);
    };

    return (
        <button className="btn bg-green-500 text-white w-full mb-5" onClick={exportToExcel}>
            Excel - ში დაექსპორტება
        </button>
    );
};

export default ExportReaches;
