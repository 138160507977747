import React from 'react';
import * as XLSX from 'xlsx';

const ExportToExcel = ({ data, fileName = 'data.xlsx' }) => {
    const exportToExcel = () => {
        // Flatten the social data into a readable format
        const flattenedData = data.map(user => ({
            ...user,
            tour: user.tour?.id || user.tour.id || "",
            fb: user.social?.fb || user.fb || '',
            yt: user.social?.yt || user.yt || '',
            ig: user.social?.ig || user.ig || '',
            tk: user.social?.tk || user.tk || ''
        }));

        // Create a new worksheet
        const ws = XLSX.utils.json_to_sheet(flattenedData);

        // Create a new workbook
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Users');

        // Save the workbook as a file
        XLSX.writeFile(wb, fileName);
    };

    return (
        <button className="btn bg-green-500 text-white" onClick={exportToExcel}>
            Excel - ში დაესპორტება
        </button>
    );
};

export default ExportToExcel;
