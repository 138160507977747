export const maskEmail = (email) => {
    if (!email) return '';

    const parts = email.split('@');
    if (parts.length !== 2) return email;

    const username = parts[0];
    const domain = parts[1];

    if (username.length <= 3) {
      return `${username.charAt(0)}...${username.charAt(username.length - 1)}@${domain}`;
    } else {
      return `${username.charAt(0)}...${username.charAt(3)}@${domain}`;
    }
};