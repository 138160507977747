import React, { useEffect, useState } from 'react'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import ParticipantsArchive from './archeivePages/ParticipantsArchive';
import ScreensArchive from './archeivePages/ScreensArchive';
import ToursArchive from './archeivePages/ToursArchive';
import axios from "../../../libs/axios"
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { getAllParticipants } from '../../../redux/thunks/participants';
import Loader from '../../../components/Loader';

const Archive = () => {

    const [inpVal, setInpVal] = useState('');
    const [winners, setWinners] = useState([]);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const participants = useSelector((state) => state.participants);

    useEffect(() => {
        dispatch(getAllParticipants({ hidden: false }))
    }, [dispatch])

    const archiveAllTheData = async () => {
        setLoading(true)
        if(!inpVal || winners.length <= 0) {
            setLoading(false)
            return toast.error("შეიყვანეთ წელი და აირჩიეთ გამარჯვებულები")
        }
        axios.post('/archive', { date: inpVal, winners: winners })
            .then(res => {
                window.location.href = "/admin/archive"
                toast.success("წარმატებით დაარქივდა")
                setLoading(false);
            })
            .catch(err => {
                toast.success("დაფიქსირდა შეცდომა")
                console.error(err)
                setLoading(false);
            })
    }
    const onChange = (e) => {
        setInpVal(e.target.value);
    }

    const onClickHandler = (id) => {
        if(!id) return null;
        if(winners.includes(id)) return setWinners(winners.filter((_id) => _id !== id))
        setWinners((prev) => [...prev, id])
    }

    let mutablePeople = participants.data && [...participants?.data];

    return (
        <div>
            <Tabs selectedTabClassName='tab-active' className='mt-5'>
                <a href='#my_modal' className='btn bg-purple-500 text-white w-full'>დაარქივება</a>
                <div className="divider"></div>
                <TabList className='m-0 flex flex-col sm:flex-row'>
                    <Tab className="tab flex justify-center items-center w-full sm:w-1/3 tab-lg tab-lifted font-fira-go">მონაწილეები</Tab>
                    <Tab className="tab flex justify-center items-center w-full sm:w-1/3 tab-lg tab-lifted font-fira-go">სქრინშოთები</Tab>
                    <Tab className="tab flex justify-center items-center w-full sm:w-1/3 tab-lg tab-lifted font-fira-go">პაკეტები</Tab>
                </TabList>
                <div className="divider"></div>
                <TabPanel>
                    <div className='flex flex-wrap gap-8'>
                        <ParticipantsArchive />
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className='flex flex-wrap gap-8'>
                        <ScreensArchive />
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className='flex flex-wrap gap-8'>
                        <ToursArchive />
                    </div>
                </TabPanel>
            </Tabs>
            <div className="modal" id='my_modal'>
                <form method="dialog" className="modal-box w-11/12 max-w-5xl">
                    <label htmlFor="year">შეიყვანეთ წელი</label><br />
                    <input disabled={loading} type='number' min={0} name='year' value={inpVal} onChange={onChange} className='border-2 w-full mt-2 p-1' />
                    <label htmlFor="year">აირჩიეთ გამარჯვებულები</label><br />
                    <h2 className='flex justify-start items-start gap-1'>მონიშნეთ ის მონაწილე რომელიც გავიდა <span className='bg-green-300 w-5 h-5 flex justify-center items-center rounded-full'>{winners.length + 1}</span> ადგილზე</h2>
                    <div className='my-1 flex flex-col justify-start items-start'>
                        {mutablePeople && mutablePeople?.sort((a, b) => a.name.localeCompare(b.name))?.map((participant, i) => 
                            <button className={'flex justify-center ml-1 gap-1'} onClick={() => onClickHandler(participant._id)} key={i}>
                                <h2>{participant.name} {participant.lastname}</h2>
                                {winners.includes(participant._id) && <span className='bg-green-300 w-5 h-5 rounded-full flex justify-center items-center'>{winners.indexOf(participant._id) + 1}</span>}
                            </button>
                        )}
                    </div>
                    <p className='text-center my-2'>დარწმუნებული ხართ რომ გსურთ დაარქივოთ ყველაფერი?</p>
                    <button disabled={loading} className='btn bg-red-500 text-white w-full' onClick={archiveAllTheData}>
                        {loading ? "loading..." : "დაარქივება"}
                    </button>
                    <div className="modal-action">
                        <a disabled={loading} href="#" className="btn" onClick={() => setInpVal('')}>დახურვა</a>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Archive