import React, { useEffect } from 'react'
import Hero from '../../components/Main/Hero';
import SocialFeeds from '../../components/Main/Socials/Index';
import Map from '../../components/Main/Map';
import Winners from '../../components/Winners';
import { useDispatch, useSelector } from 'react-redux';
import { fetchContentVideo } from '../../redux/thunks/content';

const Home = () => {
  const dispatch = useDispatch();
  const videoUrl = useSelector((state) => state.content);
  const isLoading = videoUrl.status !== 'loaded';

  useEffect(() => {
    dispatch(fetchContentVideo())
  }, [dispatch])

  return (
    <div>
      <Hero isLoading={isLoading} content={'\n gamomyeviaWaraSi \n da moige \n100 000 '} video_url={videoUrl?.video} />
      <Winners />
      <SocialFeeds />
      <div className='px-[30px] lg:px-[200px]'>
        <Map />
      </div>
    </div>
  )
}
export default Home;