import React from 'react'

const CheckEmail = () => {
    return (
        <div className='w-full h-[200px] flex justify-center items-center'>
            <p className='text-green-500 font-bolder text-2xl'>შეამოწმეთ თქვენი ელ. ფოსტა.</p>
        </div>
    )
}

export default CheckEmail