import React, { useEffect, useState } from 'react';
import axios from "../../../../libs/axios";
import PlaceCard from '../../../../components/Profile/TabSingles/TourPackage/PlaceCard';
import { getYearOnly } from '../../../../utils/getYearOnly';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

const ToursArchive = () => {
    const [tabDate, setTabDate] = useState(0);
    const [years, setYears] = useState([]);
    const [expandedTourId, setExpandedTourId] = useState(null);
    const [archivedData, setArchivedData] = useState([])


    const handleTabClick = (date) => {
        setTabDate(date);
    };

    const handleAccordionClick = (id) => {
        setExpandedTourId(expandedTourId === id ? null : id);
    };

    useEffect(() => {
        axios.get(`/archive`)
            .then((res) => setYears(res.data))
            .catch(err => console.error(err));
    }, [tabDate]);

    const getArchivedScreens = async (year) => {
        try {
            const response = await axios.get(`/archive/tours?date=${year}`);
            setArchivedData(response.data);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        if (tabDate !== 0) {
            getArchivedScreens(tabDate);
        }
    }, [tabDate]);

    return (
        <div className='w-full'>
            <Tabs>
                <TabList className='flex gap-x-1'>
                    {years.map((year, _index) => (
                        <Tab
                            key={_index}
                            className={`py-2 px-4 cursor-pointer ${tabDate === getYearOnly(year.date) ? '!bg-purple-500 text-white' : 'bg-gray-100'}`}
                            onClick={() => handleTabClick(getYearOnly(year.date))}
                        >
                            {getYearOnly(year.date)}
                        </Tab>
                    ))}
                </TabList>
                <TabPanel>
                    {archivedData.map((tour) => (
                        <div key={tour.id} className="mb-4 border rounded shadow-sm w-full">
                            <div
                                className="cursor-pointer bg-gray-200 p-4"
                                onClick={() => handleAccordionClick(tour.id)}
                            >
                                <h3 className="text-lg font-medium">პაკეტი {tour.id}</h3>
                            </div>
                            {expandedTourId === tour.id && (
                                <div className="p-4 bg-white flex flex-wrap gap-4">
                                    {tour.places?.map((item, index) => (
                                        <PlaceCard key={index} id={index} {...item} />
                                    ))}
                                </div>
                            )}
                        </div>
                    ))}
                </TabPanel>
            </Tabs>
        </div>
    );
};

export default ToursArchive;
