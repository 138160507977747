import { useForm, Controller } from 'react-hook-form';
import Input from '../Forms/formComponents/Input';
import axios from '../../libs/axios'
import toast from 'react-hot-toast'
export default function Submit({ isSubmitting }) {
    return (
        <button type="submit" className="btn btn-neutral" disabled={isSubmitting}>
            {isSubmitting ? (
                <>
                    <span className="loading loading-spinner"></span>
                    იგზავნება...
                </>
            ) : (
                'გაგზავნა'
            )}
        </button>
    );
}

export function ContactForm() {
    const { control, handleSubmit, formState: { isSubmitting }, reset } = useForm();
    const onSubmit = async (data) => {
        axios.post('/email/contact', {
            name: data.name,
            email: data.email,
            subject: data.subject,
            text: data.text
        }).then((res) => {
            toast.success(res.data.message);
            reset();
        }).catch(() => {
            toast.error("დაფიქსირდა შეცდომა!")
        })
    };

    return (
        <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col gap-4 my-6 w-full">
                <Controller
                    name="name"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                        <Input
                            label="სახელი"
                            placeholder="თქვენი სახელი"
                            required
                            {...field}
                        />
                    )}
                />
                <Controller
                    name="email"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                        <Input
                            type="email"
                            label="ელ. ფოსტა"
                            placeholder="თქვენი ელ. ფოსტა"
                            required
                            {...field}
                        />
                    )}
                />
                <Controller
                    name="subject"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                        <Input
                            label="სათაური"
                            placeholder="წერილის სათაური"
                            required
                            {...field}
                        />
                    )}
                />
                <Controller
                    name="text"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                        <div>
                            <label className="text-lg font-medium text-gray-900">
                                <span className="text-base label-text">წერილი</span>
                            </label>
                            <textarea
                                className="textarea textarea-bordered w-full"
                                placeholder="თქვენი წერილი..."
                                required
                                {...field}
                            ></textarea>
                        </div>
                    )}
                />
                <div>
                    <Submit isSubmitting={isSubmitting} />
                </div>
            </div>

        </form>
    );
}
