const Facebook = () => {
    // useEffect(() => {
    //     const script = document.createElement('script');
    //     script.src = 'https://widgets.sociablekit.com/facebook-hashtag-posts/widget.js';
    //     script.async = true;
    //     script.defer = true;
    //     document.body.appendChild(script);

    //     return () => {
    //         document.body.removeChild(script);
    //     };
    // }, []);

    // return (
    //     <div className='sk-ww-facebook-hashtag-feed' data-embed-id='25428982'></div>
    // );
    return <iframe src='https://widgets.sociablekit.com/facebook-hashtag-posts/iframe/25428982' title="facebook" frameborder='0' width='100%' height='1000'></iframe>
}

export default Facebook;