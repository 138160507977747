import 'leaflet/dist/leaflet.css';
import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import customMarkerIcon from '../../assets/icons/map-pin.svg';

const center = [41.64667954066948,  41.63349286860216];
const location = {name: "აჭარის ა. რ. ტურიზმისა და კურორტების დეპარტამენტი", lan: 41.64667954066948, lon:  41.63349286860216};

const customIcon = L.icon({
    iconUrl: customMarkerIcon,
    iconSize: [30, 30], // Size of the icon
    iconAnchor: [15, 30], // Point of the icon which will correspond to marker's location
    popupAnchor: [0, -30], // Point from which the popup should open relative to the iconAnchor
});

const ContactMap = () => {
  return (
    <MapContainer center={center} zoom={15} scrollWheelZoom={false}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
        <Marker icon={customIcon} position={[location.lan, location.lon]}>
            <Popup>
                {location.name}
            </Popup>
        </Marker>
    </MapContainer>
  );
};


export default ContactMap;
