import { createSlice } from "@reduxjs/toolkit";
import { fetchContentAbout, fetchContentTerms, fetchContentVideo } from "../thunks/content";

const initialState = {
  terms: '',
  video: '',
  about: '',
  status: "loading",
};

const contentSlice = createSlice({
  name: "content",
  initialState,
  reducers: {
    addVideo: (state, action) => {
      console.log(action)
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchContentTerms.pending, (state) => {
        state.status = "loading";
        state.terms = null;
      })
      .addCase(fetchContentTerms.fulfilled, (state, action) => {
        state.status = "loaded";
        state.terms = action.payload;
      })
      .addCase(fetchContentTerms.rejected, (state) => {
        state.status = "error";
        state.terms = null;
      })
      .addCase(fetchContentAbout.pending, (state) => {
        state.status = "loading";
        state.about = null;
      })
      .addCase(fetchContentAbout.fulfilled, (state, action) => {
        state.status = "loaded";
        state.about = action.payload;
      })
      .addCase(fetchContentAbout.rejected, (state) => {
        state.status = "error";
        state.about = null;
      })
      .addCase(fetchContentVideo.pending, (state) => {
        state.status = "loading";
        state.video = null;
      })
      .addCase(fetchContentVideo.fulfilled, (state, action) => {
        state.status = "loaded";
        state.video = action.payload;
      })
      .addCase(fetchContentVideo.rejected, (state) => {
        state.status = "error";
        state.video = null;
      });
  },
});

export const contentReducer = contentSlice.reducer;
export const { addVideo } = contentSlice.actions;