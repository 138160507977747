import * as Yup from "yup";

const objectSchema = Yup.object().shape({
  name: Yup.string().required("შეიყვანეთ: სახელი"),
  last_name: Yup.string().required("შეიყვანეთ: გვარი"),
  mobile: Yup.string().required("შეიყვანეთ: მობილური"),
  email: Yup.string().required("შეიყვანეთ ემაილი"),
  password: Yup.string()
    .min(8, "პაროლი უნდა შეიცავდეს მინიმუმ 8 სიმბოლოს")
    .required("შეიყვანეთ: პაროლი"),
  rePassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "პაროლები არ ემთხვევა")
    .required("გაიმეორეთ პაროლი"),
  object_name: Yup.string().required("შეიყვანეთ: ობიექტის სახელი"),
  object_type: Yup.string().required("აირჩიეთ ობიექტის ტიპი"),
  address: Yup.string().required("შეიყვანეთ: მისამართი"),
  discount: Yup.number().max(100, "მაქსიმალური 100"),
  time_from: Yup.string(),
  time_to: Yup.string(),
  description: Yup.string().required("აუცილებელია"),
  facebook: Yup.string()
  .url('Facebook URL არ არის ვალიდური')
  .matches(/^https:\/\/(www\.)?facebook\.com\//, 'შეიყვანეთ ვალიდური URL')
  .transform((value, originalValue) => {
      if (!value?.startsWith('https://www') && value?.toLowerCase()?.startsWith('facebook.com')) {
          return `https://www.${value}`;
      }
      return value;
  })
  .nullable(),
  instagram: Yup.string()
  .url('Instagram URL არ არის ვალიდური')
  .matches(/^https:\/\/(www\.)?instagram\.com\//, 'შეიყვანეთ ვალიდური URL')
  .transform((value, originalValue) => {
      if (!value?.startsWith('https://www') && value?.toLowerCase()?.startsWith('instagram.com')) {
          return `https://www.${value}`;
      }
      return value;
  })
  .nullable(),
  images: Yup.array()
    .of(Yup.string())
    .required("გთხოვთ ატვირთოთ მინიმუმ ერთი სურათი"),
});

export default objectSchema;
