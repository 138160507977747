import { createSlice } from "@reduxjs/toolkit";
import {
  fetchParticipantAuth,
  fetchAuthMe,
  fetchParticipantRegister,
  fetchAdminAuth,
  fetchObjectAuth,
  fetchObjectRegister,
  fetchObjectEdit,
  fetchParticipantEdit,
} from "../thunks/authThunks";

const initialState = {
  data: null,
  status: "loading",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.data = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchParticipantAuth.pending, (state) => {
        state.status = "loading";
        state.data = null;
      })
      .addCase(fetchParticipantAuth.fulfilled, (state, action) => {
        state.status = "loaded";
        state.data = action.payload;
      })
      .addCase(fetchParticipantAuth.rejected, (state) => {
        state.status = "error";
        state.data = null;
      })
      .addCase(fetchAuthMe.pending, (state) => {
        state.status = "loading";
        state.data = null;
      })
      .addCase(fetchAuthMe.fulfilled, (state, action) => {
        state.status = "loaded";
        state.data = action.payload;
      })
      .addCase(fetchAuthMe.rejected, (state) => {
        state.status = "error";
        state.data = null;
      })
      .addCase(fetchParticipantRegister.pending, (state) => {
        state.status = "loading";
        state.data = null;
      })
      .addCase(fetchParticipantRegister.fulfilled, (state, action) => {
        state.status = "loaded";
        state.data = action.payload;
      })
      .addCase(fetchParticipantRegister.rejected, (state) => {
        state.status = "error";
        state.data = null;
      })
      // admin
      .addCase(fetchAdminAuth.pending, (state) => {
        state.status = "loading";
        state.data = null;
      })
      .addCase(fetchAdminAuth.fulfilled, (state, action) => {
        state.status = "loaded";
        state.data = action.payload;
      })
      .addCase(fetchAdminAuth.rejected, (state) => {
        state.status = "error";
        state.data = null;
      })
      //object
      .addCase(fetchObjectAuth.pending, (state) => {
        state.status = "loading";
        state.data = null;
      })
      .addCase(fetchObjectAuth.fulfilled, (state, action) => {
        state.status = "loaded";
        state.data = action.payload;
      })
      .addCase(fetchObjectAuth.rejected, (state) => {
        state.status = "error";
        state.data = null;
      })
      .addCase(fetchObjectRegister.pending, (state) => {
        state.status = "loading";
        state.data = null;
      })
      .addCase(fetchObjectRegister.fulfilled, (state, action) => {
        state.status = "loaded";
        state.data = action.payload;
      })
      .addCase(fetchObjectRegister.rejected, (state) => {
        state.status = "error";
        state.data = null;
      })
      .addCase(fetchObjectEdit.pending, (state) => {
        state.status = "loading";
        state.data = null;
      })
      .addCase(fetchObjectEdit.fulfilled, (state, action) => {
        state.status = "loaded";
        state.data = action.payload;
      })
      .addCase(fetchObjectEdit.rejected, (state) => {
        state.status = "error";
        state.data = null;
      })
      .addCase(fetchParticipantEdit.pending, (state) => {
        state.status = "loading";
        state.data = null;
      })
      .addCase(fetchParticipantEdit.fulfilled, (state, action) => {
        state.status = "loaded";
        state.data = action.payload;
      })
      .addCase(fetchParticipantEdit.rejected, (state) => {
        state.status = "error";
        state.data = null;
      });
  },
});

export const selectIsAuth = (state) => Boolean(state.auth.data);

export const authReducer = authSlice.reducer;
export const { logout } = authSlice.actions;
