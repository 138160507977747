import ObjLoginForm from "../../components/Forms/objects/ObjLoginForm";

export default function ObjLogin({ off }) {
    if(off){
        return (
            <div className="w-full md:w-2/3 mx-auto p-6 h-[80dvh]">
                <div className="card w-full lg:w-1/2 bg-base-100 shadow-xl mx-auto mt-6">
                    <div className="card-body text-center">
                        რეგისტრაცია დაიწყება 00:00 საათიდან
                    </div>
                </div>
            </div>
        )
    }
    
    return (
        <>
            <div className='w-full lg:w-3/4 mx-auto p-6'>
                <div className='card w-full lg:w-2/3 bg-base-100 shadow-xl mx-auto mt-6'>
                    <div className='card-body'>
                        {/* <p className="text-2xl font-bold text-center my-10 text-red-600">რეგისტრაცია დაიწყება 1 აგვისტოს!</p> */}
                        <ObjLoginForm />
                    </div>
                </div>
            </div>
        </>
    );
}