import { Link, useNavigate } from 'react-router-dom';
import location from '../../assets/icons/objects/location.svg';
import clock from '../../assets/icons/objects/clock.svg';
import useCurrentUser from '../../hooks/useCurrentUser';
import { Swiper, SwiperSlide } from 'swiper/react';
import { A11y, Navigation, Pagination, Scrollbar } from 'swiper/modules';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
// import { dashboardApi } from '../api/api';
// import { Item } from './Dashboard.interface';

const ObjectDashboard = () => {
    const navigate = useNavigate();
    const { user } = useCurrentUser();
    useEffect(() => {
        if (!user?.verified) {
            navigate('/email/verify');
            toast.success('გაიარეთ ვერიფიკაცია');
        }
    }, [user, navigate]);
    
    return (
        <div>
            {/* Display user-specific content */}
            {true ? (
                <div>
                    <div className='container-2xl mx-auto '>
                        {true ? (
                            <div
                                className={`grid md:pr-8 grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4 md:gap-2`}
                            >
                                <div className='portfolio'>
                                    <div className='avatar pt-5 align-middle flex justify-center'>
                                        <div className='relative flex text-center items-center justify-center w-20 h-20 overflow-hidden text-3xl bg-gray-100 rounded-full dark:bg-gray-600'>
                                            <img className='w-full h-full' src={`${process.env.REACT_APP_EXPRESS_URL}${user?.images[0]}`} alt='...' />
                                            {/* <span className='font-medium text-gray-600 dark:text-gray-300'>
                                            </span> */}
                                        </div>
                                    </div>

                                    <h2 className='text-white text-center font-bold text-lg pt-4'>
                                        {user?.name}
                                    </h2>

                                    <div className='detail clearfix pt-10 text-slate-400'>
                                        <ul className='mb-0'>
                                            <li>
                                                <span>
                                                    <svg
                                                        xmlns='http://www.w3.org/2000/svg'
                                                        fill='none'
                                                        viewBox='0 0 24 24'
                                                        strokeWidth='1.5'
                                                        stroke='currentColor'
                                                        className='w-6 h-6'
                                                    >
                                                        <path
                                                            strokeLinecap='round'
                                                            strokeLinejoin='round'
                                                            d='M15 10.5a3 3 0 11-6 0 3 3 0 016 0z'
                                                        />
                                                        <path
                                                            strokeLinecap='round'
                                                            strokeLinejoin='round'
                                                            d='M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z'
                                                        />
                                                    </svg>
                                                    <strong className='ml-2'>{user?.address}</strong>
                                                </span>
                                            </li>
                                            <li>
                                                <span className='active'>
                                                    <svg
                                                        xmlns='http://www.w3.org/2000/svg'
                                                        fill='none'
                                                        viewBox='0 0 24 24'
                                                        strokeWidth='1.5'
                                                        stroke='currentColor'
                                                        className='w-6 h-6'
                                                    >
                                                        <path
                                                            strokeLinecap='round'
                                                            strokeLinejoin='round'
                                                            d='M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75'
                                                        />
                                                    </svg>
                                                    <strong className='ml-2'>{user?.email}</strong>
                                                </span>
                                            </li>
                                            <li>
                                                <span>
                                                    <svg
                                                        xmlns='http://www.w3.org/2000/svg'
                                                        fill='none'
                                                        viewBox='0 0 24 24'
                                                        strokeWidth='1.5'
                                                        stroke='currentColor'
                                                        className='w-6 h-6'
                                                    >
                                                        <path
                                                            strokeLinecap='round'
                                                            strokeLinejoin='round'
                                                            d='M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z'
                                                        />
                                                    </svg>
                                                    <strong className='ml-2'>{user?.mobile}</strong>
                                                </span>
                                            </li>
                                            <li>
                                                <span>
                                                    <svg
                                                        xmlns='http://www.w3.org/2000/svg'
                                                        fill='none'
                                                        viewBox='0 0 24 24'
                                                        strokeWidth='1.5'
                                                        stroke='currentColor'
                                                        className='w-6 h-6'
                                                    >
                                                        <path
                                                            strokeLinecap='round'
                                                            strokeLinejoin='round'
                                                            d='M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z'
                                                        />
                                                    </svg>
                                                    <strong className='ml-2 text-wrap'>
                                                        {(user?.time_to && user?.time_from) ? `${user.time_from}-${user.time_to}` : 'არ არის მოცემული'}
                                                    </strong>

                                                </span>
                                            </li>
                                            <li>
                                                <Link to={`/object/edit`}>
                                                    <span className='cursor-pointer'>
                                                        <svg
                                                            xmlns='http://www.w3.org/2000/svg'
                                                            fill='none'
                                                            viewBox='0 0 24 24'
                                                            strokeWidth='1.5'
                                                            stroke='currentColor'
                                                            className='w-6 h-6'
                                                        >
                                                            <path
                                                                strokeLinecap='round'
                                                                strokeLinejoin='round'
                                                                d='M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10'
                                                            />
                                                        </svg>
                                                        <strong className='ml-2'>რედაქტირება</strong>
                                                    </span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <Link className='w-full' to={`/objects/${user?._id}`}>
                                    <div
                                        className='card card-compact cursor-pointer'
                                        key={2}
                                    >
                                        <div className='card-body'>
                                            <div className='avatar'>
                                                <div className='w-full h-80 rounded-t-lg border-b-4 border-solid border-white object-cover'>
                                                    <Swiper
                                                        className='w-full'
                                                        modules={[Navigation, Pagination, Scrollbar, A11y]}
                                                        spaceBetween={50}
                                                        slidesPerView={1}
                                                        navigation
                                                        pagination={{ clickable: true }}
                                                    >
                                                        {user?.images.map((image, _index) => (
                                                            <SwiperSlide>
                                                                <img src={`${process.env.REACT_APP_EXPRESS_URL}${image}`} alt='...' />
                                                            </SwiperSlide>
                                                        ))}
                                                    </Swiper>
                                                </div>
                                            </div>
                                            <div className='text-left p-5'>
                                                <div className='flex items-center flex-col justify-between'>
                                                    <div className='card-title'>
                                                        <p className='text-gray-500'>
                                                            {user?.object_type}
                                                        </p>
                                                        <p className='text-slate-400 text-sm font-bold'>
                                                            {user?.object_name}
                                                        </p>
                                                    </div>
                                                    <div className='active flex items-center gap-1'>
                                                        {user?.email}
                                                        <svg
                                                            xmlns='http://www.w3.org/2000/svg'
                                                            fill='none'
                                                            viewBox='0 0 24 24'
                                                            strokeWidth='1.5'
                                                            stroke='currentColor'
                                                            className='w-6 h-6'
                                                        >
                                                            <path
                                                                strokeLinecap='round'
                                                                strokeLinejoin='round'
                                                                d='M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75'
                                                            />
                                                        </svg>
                                                    </div>
                                                </div>
                                                <div className='mb-5'>
                                                    <div className='flex items-center justify-between'>
                                                        <div className='text-slate-400 flex items-center'>
                                                            <img
                                                                className='mr-2'
                                                                src={clock}
                                                                alt='clock'
                                                                width={16}
                                                                height={16}
                                                            />
                                                            {(user?.time_to && user?.time_from) ? `${user.time_from}-${user.time_to}` : 'არ არის მოცემული'}
                                                            {/* {false ? (
                                                                <>{console.log()}</>
                                                            ) : (
                                                                <p>9:00</p>
                                                            )} */}
                                                        </div>
                                                        <div className='text-slate-400'>
                                                            <div className='text-sm flex items-center'>
                                                                <img
                                                                    className='mr-2'
                                                                    src={location}
                                                                    alt='place'
                                                                    width={16}
                                                                    height={16}
                                                                />
                                                                {user?.address}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {false ? <>{console.log()}</> : <p></p>}
                                                <div className='text-slate-400'>
                                                    <p>activity_type</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            ) : (
                <></>
            )}
        </div>
    );
};

export default ObjectDashboard;