import React, { useState } from 'react'
import { maskEmail } from '../../utils/maskEmail';
import axios from '../../libs/axios';
import { BeatLoader } from 'react-spinners';

export default function EmailSend({ email, nextStep, role }) {
  const maskedEmail = maskEmail(email);
  const [loading, setLoading] = useState(false);

  const onSendVerifyCode = () => {
        setLoading(true)
        axios.post(`/${role}/send/verify`, {
            email: email
        })
        .then((res) => {
            nextStep();
            setLoading(false);
        }).catch((err) => {
            setLoading(false);
            console.log(err)
        })
  }

  return (
    <div className="w-full md:w-2/3 h-[85dvh] flex justify-center items-center mx-auto p-6">
          <div className="card w-full lg:w-1/2 bg-base-100 shadow-xl mx-auto mt-6">
              <div className="card-body flex justify-center items-start flex-col gap-5">
              {loading 
                    ? <div className='w-full h-full flex justify-center items-center'>
                        <BeatLoader/>
                      </div>
                    : (
                        <>
                                <h2>გაგზავნეთ დამადასტურებელი კოდი ემაილზე: {maskedEmail}</h2>
                                <button disabled={loading} onClick={onSendVerifyCode} type='button' className='px-8 bg-green-600 text-white rounded-full py-1'>გაგზავნა</button>
                        </>
                    )           
                }
                
              </div>
          </div>
    </div>
  )
}
