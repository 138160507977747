import React, { useEffect } from 'react';
import AboutImage from "../../assets/bgImages/aboutAndTermsBanner.jpg"
import { fetchContentAbout } from '../../redux/thunks/content';
import { useDispatch, useSelector } from 'react-redux';

// const supabase = createClient('your-supabase-url', 'your-supabase-anon-key');

const About = () => {
    // const [content, setContent] = useState('');

    // useEffect(() => {
    //     const fetchContent = async () => {
    //         let { data, error } = await supabase
    //             .from('contents')
    //             .select('*')
    //             .eq('name', 'about')
    //             .single();

    //         if (error) {
    //             console.error('Error fetching content:', error);
    //         } else {
    //             setContent(data.content);
    //         }
    //     };

    //     fetchContent();
    // }, []);
    const dispatch = useDispatch();
    const content = useSelector((state) => state.content);
    const isLoading = content.status !== 'loaded';
    
    useEffect(() => {
      dispatch(fetchContentAbout())
    }, [dispatch])

    return (
        <div className='min-h-[70dvh]'>
            <img src={AboutImage} className='w-full object-cover' alt='გვერდი' width={1600} height={200} />
            <div className="container flex justify-center items-center mx-auto my-10">
                <div className="mx-5 sm:m-auto max-w-sm md:max-w-5xl">
                    <article className="prose max-w-full text-justify">
                        <h1 className="text-center text-2xl md:text-4xl">პროექტის შესახებ</h1>
                        {!isLoading && <div className="text-black" dangerouslySetInnerHTML={{ __html: content?.about }}></div>}
                    </article>
                </div >
            </div >
        </div>
    );
};

export default About;
