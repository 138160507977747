import React, { useEffect, useState } from 'react';
import useCurrentUser from '../../hooks/useCurrentUser';
import { useNavigate, useParams } from 'react-router-dom';
import axios from '../../libs/axios';
import toast from 'react-hot-toast';

const ResetPassword = () => {
  const [inpVal, setInpVal] = useState('');
  const { user } = useCurrentUser();
  const navigate = useNavigate();
  const { role } = useParams()
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user) {
      navigate('/');
    }
  }, [user, navigate]);

  const submitResetPass = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      await axios.post(`/${role}/send-password-reset-link`, { email: inpVal });
      toast.success('პაროლის აღდგენის ბმული წარმატებით გაიგზავნა');
      navigate('/reset-password/check');
    } catch (err) {
      if (err.response && err.response.status === 404) {
        toast.error('მომხმარებლის მეილი არასწორია'); // Display error message if email not found
      } else {
        console.error(err); // Log any other errors
        toast.error('დაფიქსირდა შეცდომა, სცადეთ მოგვიანებით'); // Display a generic error message
      }
    }
  };

  return (
    <div className='card w-full md:w-2/3 lg:w-2/3 bg-base-100 shadow-xl mx-auto mt-6 p-10'>
      <div className="card flex flex-col gap-y-5">
        <form className='flex flex-col md:flex-row justify-center items-center w-full gap-2' onSubmit={submitResetPass}>
          <input value={inpVal} onChange={(e) => setInpVal(e.target.value)} type='email' placeholder='შეიყვანეთ მეილი' className='p-3 border-2' required />
          <button disabled={loading} className='btn bg-green-300 text-white rounded-md p-3' type='submit'>მეილის გაგზავნა</button>
        </form> 
      </div>
    </div>
  );
};

export default ResetPassword;
