import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import Input from './formComponents/Input';
import axios from "../../libs/axios"
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

const ResetPassForm = ({ role, token }) => {
    const { control, handleSubmit, watch, formState: { errors } } = useForm();
    const navigate = useNavigate();
    const onSubmit = (data) => {
        axios.post(`${role}/reset-password`, { newPassword: data.password, token })
            .then(res => {
                toast.success('თქვენ წარმატებით შეცვალეთ პაროლი!')
                navigate('/')
            })
            .catch(err => toast.error('დაფიქსირდა შეცდომა'))
    };

    const password = watch('password', '');

    return (
        <div className="max-w-md mx-auto">
            <form className='space-y-4 my-4' onSubmit={handleSubmit(onSubmit)}>
                <Controller
                    name="password"
                    control={control}
                    rules={{
                        required: 'პაროლი აუცილებელია',
                        minLength: {
                            value: 8,
                            message: 'პაროლი უნდა შეიცავდეს მინიმუმ 8 სიმბოლოს'
                        }
                    }}
                    render={({ field }) => (
                        <Input
                            {...field}
                            label="პაროლი"
                            type="password"
                            placeholder="შეიყვანეთ პაროლი"
                        />
                    )}
                />
                {errors.password && <span className="text-red-700 text-sm mt-2">{errors.password.message}</span>}

                <Controller
                    name="repeatPassword"
                    control={control}
                    rules={{
                        required: 'გაიმეორეთ პაროლი',
                        validate: value =>
                            value === password || 'პაროლები არ ემთხვევა'
                    }}
                    render={({ field }) => (
                        <Input
                            {...field}
                            label="გაიმეორეთ პაროლი"
                            type="password"
                            placeholder="გაიმეორეთ პაროლი"
                        />
                    )}
                />
                {errors.repeatPassword && <span className="text-red-700 text-sm mt-2">{errors.repeatPassword.message}</span>}

                <div>
                    <button className="btn btn-block btn-primary" type='submit'>
                        შეცვლა
                    </button>
                </div>
            </form>
        </div>
    );
};

export default ResetPassForm;
