// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-tabs__tab-list {
    border-bottom: 0 !important;
}

.tab:visited {
    border-color: transparent;
}

.tab:active {
    border-color: transparent;
}
.tab{
    border: none;
    outline: 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/profile/profile.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;AAC/B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;AACA;IACI,YAAY;IACZ,UAAU;AACd","sourcesContent":[".react-tabs__tab-list {\n    border-bottom: 0 !important;\n}\n\n.tab:visited {\n    border-color: transparent;\n}\n\n.tab:active {\n    border-color: transparent;\n}\n.tab{\n    border: none;\n    outline: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
