import { useState } from 'react';
import ImageViewer from 'react-simple-image-viewer';

const SocialReachesSingleItem = ({ views, image }) => {
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);

    const openImageViewer = (index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    };

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };
    return (
        <div>
            <h4 className="text-md">Reach-{views} {1} {2}</h4>
            <img
                onClick={() => openImageViewer(0)}
                style={{
                    width: 200,
                    height: 300,
                    objectFit: 'cover',
                    cursor: 'pointer'
                }}
                // src={`https://qncbnxbxcvvacstnmmdk.supabase.co/storage/v1/object/public/screens/${image}`}
                src={`${process.env.REACT_APP_EXPRESS_URL}${image}`}
                alt="Shoes" />

            {isViewerOpen && (
                <ImageViewer
                    src={[
                        // `https://qncbnxbxcvvacstnmmdk.supabase.co/storage/v1/object/public/screens/${image}`
                        // 'https://picsum.photos/300'
                        `${process.env.REACT_APP_EXPRESS_URL}${image}`
                    ]}
                    currentIndex={currentImage}
                    disableScroll={false}
                    closeOnClickOutside={true}
                    onClose={closeImageViewer}
                />
            )}
        </div>
    );
};

export default SocialReachesSingleItem;
