import React from 'react'
import LoginForm from '../../components/Forms/LoginForm';

const AdminLogin = () => {
    return (
        <div className="w-full h-[80dvh] md:w-2/3 mx-auto p-6">
            <div className="card w-full lg:w-1/2 bg-base-100 shadow-xl mx-auto mt-6">
                <div className='bg-green-500 text-white p-5 text-center text-2xl rounded-t-2xl'>ადმინის ვერსია</div>
                <div className="card-body">
                    <LoginForm forAdmin={true} />
                </div>
            </div>
        </div>
    )
}

export default AdminLogin