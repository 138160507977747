import React, { useState } from 'react'
import ReactCodeInput from 'react-code-input'
import axios from '../../libs/axios';
import { useNavigate } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';

const props = {
  className: ReactCodeInput,
  inputStyle: {
    fontFamily: 'monospace',
    margin: '4px',
    borderRadius: '3px',
    fontSize: '20px',
    paddingLeft: '7px',
    MozAppearance: 'textfield',
    border: '1px solid black',
  },
  inputStyleInvalid: {
    fontFamily: 'monospace',
    margin: '4px',
    borderRadius: '3px',
    fontSize: '20px',
    paddingLeft: '7px',
    border: '1px solid black'
  }
}

export default function EmailConfirm({ email, role }) {
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState('');
  const navigate = useNavigate();

  const onConfirmVerifyCode = () => {
    if (!code || code.length !== 6) return null;
    setLoading(true)
    axios.post(`/${role}/confirm/verify`, {
      email: email,
      verificationCode: code,
    })
      .then(() => {
        if (role === 'object') navigate('/object-dashboard');
        if (role === 'participant') navigate('/profile');
        window.location.reload();
        setLoading(false);
      }).catch((err) => {
        setLoading(false);
        console.log(err)
      })
  }

  return (
    <div className="w-full md:w-2/3 h-[85dvh] flex justify-center items-center mx-auto p-6">
      <div className="card w-full lg:w-1/2 bg-base-100 shadow-xl mx-auto mt-6">
        <div className="card-body flex justify-center items-center flex-col gap-5">
          {loading
            ? <div className='w-full h-full flex justify-center items-center'>
              <BeatLoader />
            </div>
            : (
              <>
                <h2>გთხოვთ ჩაწეროთ ემაილზე მოსული კოდი</h2>
                <ReactCodeInput onChange={setCode} {...props} type='number' fields={6} />
                <button onClick={onConfirmVerifyCode} type='button' className='px-8 bg-green-600 text-white rounded-full py-1'>დადასტურება</button>
              </>
            )
          }
        </div>
      </div>
    </div>
  )
}
