import { configureStore } from "@reduxjs/toolkit";
import { authReducer } from "./slices/auth";
import { participantsReducer } from "./slices/participants";
import { contentReducer } from "./slices/content";
import { objectsReducer } from "./slices/object";

const store = configureStore({
  reducer: {
    auth: authReducer,
    participants: participantsReducer,
    content: contentReducer,
    objects: objectsReducer,
  },
});

export let RootState = store.getState;
export let AppDispatch = store.dispatch;

export { store };
