import Tabs from '../../components/Profile/Tabs/Tabs';
import 'react-tabs/style/react-tabs.css';
import './profile.css';
import useCurrentUser from '../../hooks/useCurrentUser';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useEffect } from 'react';

const Profile = () => {
    const { user } = useCurrentUser();
    const navigate = useNavigate();
    // const [loading, setLoading] = useState(true);
    // Replace with your actual userI

    // useEffect(() => {
    //     const fetchProfile = async () => {
    //         const supabase = createServerComponentClient({ cookies: {} }); // Ensure cookies are correctly passed or handled
    //         const userId = "0af9a955-208c-4a9e-975e-d9a759a79006"; // Replace with your actual userId

    //         try {
    //             const { data, error, status } = await supabase
    //                 .from('profiles')
    //                 .select('*')
    //                 .eq('id', userId)
    //                 .single();

    //             if (error) {
    //                 throw error;
    //             }

    //             setData(data);
    //             setLoading(false);
    //         } catch (error) {
    //             console.error('Error fetching profile data:', error);
    //             setError(error);
    //             setLoading(false);
    //         }
    //     };

    //     fetchProfile();
    // }, []);



    // if (loading) {
    //     return <div>Loading...</div>; // Replace with your loading indicator
    // }

    // if(user?.role === "admin") return navigate('/admin');
    useEffect(() => {
        if (!user?.verified) {
            navigate('/email/verify');
            toast.success('გაიარეთ ვერიფიკაცია');
        }
    }, [user, navigate]);

    return (
        <div className="card w-full md:w-2/3 lg:w-2/3 bg-base-100 shadow-xl mx-auto mt-6">
            <div className="card-body">
                <Tabs data={user} userId={user._id} /> {/* Make sure userId is defined in scope */}
            </div>
        </div>
    );
};

export default Profile;
