import { Link, useLocation, useNavigate } from 'react-router-dom';
import mainLogo from "../assets/logos/logo.png"
import { useDispatch, useSelector } from 'react-redux';
import { logout, selectIsAuth } from '../redux/slices/auth';
// import { useEffect, useState } from 'react';

const navLinks = [
    {
        href: '/',
        name: 'მთავარი',
    },
    {
        href: '/about',
        name: 'პროექტის შესახებ',
    },
    {
        href: '/terms',
        name: 'წესები და პირობები',
    },
    {
        href: '/participants',
        name: 'მონაწილეები',
    },
    {
        href: '/register',
        name: 'რეგისტრაცია',
        protected: true,
    },
    {
        href: '/object',
        name: 'იდეა ბლოგერს',
    },
    {
        href: '/contact',
        name: 'კონტაქტი',
    },
];

const Navbar = ({ currentUser }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const isAuth = useSelector(selectIsAuth);
    const dispatch = useDispatch();
    // const [isAuth, setisAuth] = useState(null);

    // const supabase = createClientComponentClient();
    const handleSignOut = async () => {
        navigate("/")
        dispatch(logout())
        window.localStorage.removeItem('token');
    };

    const handleClick = () => {
        const elem = document.activeElement;
        if (elem) {
            setTimeout(() => {
                elem?.blur();
            }, 100);
        }
    };
    // useEffect(() => {
    //     supabase.auth.onAuthStateChange((_, sess) => {
    //         setisAuth(sess);
    //     });
    // }, []);

    const userId = typeof window !== 'undefined' ? localStorage.getItem('userId') : false;

    return (
        <div className={`navbar px-5 md:px-20 bg-base-100 shadow-sm font-fira-go`}>
            <div className='navbar-start'>
                <div className='dropdown'>
                    <label tabIndex={0} className='btn btn-ghost lg:hidden'>
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            className='h-5 w-5'
                            fill='none'
                            viewBox='0 0 24 24'
                            stroke='currentColor'
                        >
                            <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                strokeWidth='2'
                                d='M4 6h16M4 12h8m-8 6h16'
                            />
                        </svg>
                    </label>
                    <ul
                        tabIndex={0}
                        className='menu menu-sm dropdown-content mt-3 z-[9999] p-2 shadow bg-base-100 rounded-box w-52'
                    >
                        {navLinks.map((link, i) => {
                            const isActive = location.pathname.startsWith(link.href);
                            return (
                                !(link.protected && isAuth) && (
                                    <li key={`nav-mob-${i}`} onClick={handleClick}>
                                        <Link
                                            className={` ${isActive ? 'text-blue' : 'text-black'}`}
                                            to={link.href}
                                            key={link.name}
                                        >
                                            
                                            {link.name}
                                        </Link>
                                    </li>
                                )
                            );
                        })}
                    </ul>
                </div>
                <Link to='/' aria-label='Home' className='p-0 m-0'>
                    <img
                        className='w-24 h-20 md:h-auto object-contain'
                        alt=''
                        src={mainLogo}
                        width={90}
                        height={50}
                    />
                </Link>
            </div>
            <div className='navbar-center hidden lg:flex'>
                <ul className='menu menu-horizontal px-1 z-10'>
                    {navLinks.map((link, i) => {
                        const isActive = location.pathname === link.href;

                        return (
                            !(link.protected && isAuth) && (
                                <li key={`nav-${i}`}>
                                    <Link
                                        className={`hover:!bg-none hover:scale-110 border-b-cyan-800  ${isActive
                                            ? 'text-base font-semibold border-b-2 rounded-none rounded-t-md'
                                            : 'text-base'
                                            }`}
                                        to={link.href}
                                        key={link.name}
                                    >
                                        {link.name}
                                    </Link>
                                </li>
                            )
                        );
                    })}
                </ul>
            </div>
            <div className='navbar-end'>
                {isAuth ? (
                    <>
                        <div className='dropdown dropdown-end'>
                            <label tabIndex={0} className='btn btn-ghost btn-circle avatar'>
                                <div className='w-10 rounded-full'>
                                    {currentUser.role === "object" ? (
                                        <img
                                            className='w-full h-full bg-black object-cover'
                                            src={`${process.env.REACT_APP_EXPRESS_URL}${currentUser?.images[0]}`}
                                            alt='...'
                                        />
                                    ) : (
                                        <img
                                            className='w-full h-full bg-black object-cover'
                                            src={`${process.env.REACT_APP_EXPRESS_URL}${currentUser?.avatar_url}`}
                                            alt='...'
                                        />
                                    )}
                                </div>
                            </label>
                            <ul
                                tabIndex={0}
                                className='mt-3 z-20 p-2 shadow menu menu-sm dropdown-content bg-base-100 rounded-box w-52'
                            >
                                <li onClick={handleClick}>
                                    {currentUser?.role === 'admin' ? <Link to='/admin'>ადმინი</Link> : currentUser?.role === 'object' ? <Link to='/object-dashboard'>ობიექტის მართვა</Link> : <Link to='/profile'>პროფილი</Link>}
                                </li>
                                <li>
                                    <button onClick={handleSignOut}>გასვლა</button>
                                </li>
                            </ul>
                        </div>
                    </>
                ) : (
                    <Link to='/login' className='font-fira-go text-gray-600'>
                        {userId ? (
                            <Link to='/dashboard' className='font-fira-go text-gray-600'>
                                პროფილი
                            </Link>
                        ) : (
                            'შესვლა'
                        )}
                    </Link>
                )}
            </div>
        </div>
    );
};

export default Navbar;
