import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../libs/axios";

export const getAllParticipants = createAsyncThunk(
  "participants/fetchAllParticipants",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/participant?hidden=${params.hidden}`);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAllArchivedParticipants = createAsyncThunk(
  "participants/fetchAllArchivedParticipants",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/archive/participants?date=${params.date}`);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
