import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllObjects } from '../../redux/thunks/objectThunks';
import location from "../../assets/icons/objects/location.svg"
import clock from "../../assets/icons/objects/clock.svg"
const ObjectElem = () => {
    const [loading, setLoading] = useState(false);
    const objects = useSelector(state => state.objects)
    const dispatch = useDispatch();

    useEffect(() => {
        setLoading(true);
        dispatch(getAllObjects());
        // const fetchData = async () => {
        //     try {
        //         const response = await objectApi();
        //         setData(response);
        //         setLoading(true);
        //     } catch (e) {
        //         console.log(e);
        //     }
        // };

        // const fetchInfo = async () => {
        //     try {
        //         const response = await infoText();
        //         setInfo(response);
        //     } catch (error) {
        //         console.log('Error fetching info:', error);
        //     }
        // };

        // fetchData();
        // fetchInfo();
    }, [dispatch]);

    // const Dangerously = (str) => {
    //     const markup = { __html: `${str}` };
    //     return <div dangerouslySetInnerHTML={markup}></div>;
    // };

    const userId =
        typeof window !== 'undefined' ? localStorage.getItem('userId') : false;

    return (
        <>
            <div className='container mx-auto my-4 px-7'>
                <div className='info text-center pb-3'>
                    <p className='text-start mt-10'>
                        ამ გვერდზე განთავსებულია აჭარაში არსებული ტურისტული პროდუქტების წარმომადგენლების მიერ სპეციალურად პროექტის მონაწილეებისთვის შექმნილი შემოთავაზება, გაეცანი მას. შეგიძლია ეწვიო ან გამოცადო აქ მოცემული სერვისი, გადაიღო და განათავსო ფოტო და ვიდეო მასალა შენთან, კამპანიის ფარგლებში. სურვილის შემთხვევაში, დაუკავშირდი წარმომადგენელს მითითებულ საკონტაქტო ნომერზე.

                        <br />
                        <br />
                        აჭარაში მოქმედ ტურისტული პროდუქტების და სერვისების მომწოდებლებს, შეუძლიათ თავისი შეთავაზება დაარეგისტრირონ და განათავსონ მითითებული რეგისტრაციის გვერდზე გადასვლით.
                    </p>
                    {userId ? (
                        ''
                    ) : (
                        <div className='id pt-5 gap-2 flex justify-center items-center pb-5'>
                            <Link
                                className='bg-white hover:bg-gray-100 text-gray-800 font-semibold py-3 px-4 border border-gray-400 rounded shadow'
                                to={'/objlogin'}
                            >
                                შესვლა
                            </Link>
                            <Link
                                className='bg-white hover:bg-gray-100 text-gray-800 font-semibold py-3 px-4 border border-gray-400 rounded shadow'
                                to={'/objregister'}
                            >
                                რეგისტრაცია
                            </Link>
                        </div>
                    )}
                </div>
                {loading ? (
                    <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4'>
                        {objects.data?.map((item) => (
                            <Link key={item.id} to={`/objects/${item._id}`}>
                                <div className='card card-compact cursor-pointer'>
                                    <div className='card-body'>
                                        <div className='avatar'>
                                            <div className='w-full h-80 rounded-t-lg border-b-4 border-[#D98200] relative'>
                                                <div className='top-left absolute z-50'>
                                                    <div className='top-left-bg'>
                                                        <span>
                                                            {(item.discount ?? 0) >= 100 ? (
                                                                <span>
                                                                    <strong>Free</strong>
                                                                </span>
                                                            ) : (
                                                                <span>
                                                                    <strong>{item.discount} %</strong>
                                                                </span>
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className='bottom-left absolute z-50'>
                                                    {item?.object_type}
                                                </div>

                                                <img
                                                    className='w-full transition duration-300 ease-in-out hover:scale-110'
                                                    loading='lazy'
                                                    src={
                                                        `${process.env.REACT_APP_EXPRESS_URL}${item?.images[0]}` ||
                                                        'https://follow.geoevents.ge/media/media/obieqtebi/default.jpg'
                                                    }
                                                    alt={`${objects.data?.name}`}
                                                    width={400}
                                                    height={500}
                                                />
                                            </div>
                                        </div>
                                        <div className='card-content'>
                                            <div className='card-title'>
                                                <h1 className='text-3xl pt-3 pl-2'>
                                                    {item.object_name}
                                                </h1>
                                            </div>

                                            <div className='card-info'>
                                                <ul className='ul w-full'>
                                                    <li className='li'>
                                                        <img className='w-4' src={location} alt='' />
                                                        <span className='pl-2'>{item.address}</span>
                                                    </li>
                                                    <li className='li'>
                                                        <img className='w-5' src={clock} alt='' />
                                                        <span className='pl-2'>{item.time_from && item.time_to ? `${item.time_from.slice(0, 5)}-${item.time_to.slice(0, 5)}` : 'no info'}</span>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className='card-description'>
                                                <p
                                                    className='text-gray-500 pl-2 text-justify'
                                                    dangerouslySetInnerHTML={{
                                                        __html:
                                                            !item?.description
                                                                ? ''
                                                                : item.description.slice(0, 150),
                                                    }}
                                                ></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        ))}
                    </div>
                ) : (
                    <>
                        <div className='text-center w-full pb-5'>
                            <div
                                role='status'
                                className='space-y-2.5 animate-pulse w-full text-center'
                            >
                                <div className='flex items-center w-full space-x-5'>
                                    <div className='h-2.5 bg-gray-200 rounded-full dark:bg-gray-300 w-32'></div>
                                    <div className='h-2.5 bg-gray-300 rounded-full dark:bg-gray-300 w-24'></div>
                                    <div className='h-2.5 bg-gray-300 rounded-full dark:bg-gray-300 w-full'></div>
                                </div>
                                <div className='flex items-center w-full space-x-2'>
                                    <div className='h-2.5 bg-gray-200 rounded-full dark:bg-gray-300 w-full'></div>
                                    <div className='h-2.5 bg-gray-300 rounded-full dark:bg-gray-300 w-full'></div>
                                    <div className='h-2.5 bg-gray-300 rounded-full dark:bg-gray-300 w-24'></div>
                                </div>
                                <div className='flex items-center w-full space-x-2'>
                                    <div className='h-2.5 bg-gray-300 rounded-full dark:bg-gray-300 w-full'></div>
                                    <div className='h-2.5 bg-gray-200 rounded-full dark:bg-gray-300 w-80'></div>
                                    <div className='h-2.5 bg-gray-300 rounded-full dark:bg-gray-300 w-full'></div>
                                </div>
                                <div className='flex items-center w-full space-x-2'>
                                    <div className='h-2.5 bg-gray-200 rounded-full dark:bg-gray-300 w-full'></div>
                                    <div className='h-2.5 bg-gray-300 rounded-full dark:bg-gray-300 w-full'></div>
                                    <div className='h-2.5 bg-gray-300 rounded-full dark:bg-gray-300 w-24'></div>
                                </div>
                                <div className='flex items-center w-full space-x-2'>
                                    <div className='h-2.5 bg-gray-300 rounded-full dark:bg-gray-300 w-32'></div>
                                    <div className='h-2.5 bg-gray-300 rounded-full dark:bg-gray-300 w-24'></div>
                                    <div className='h-2.5 bg-gray-200 rounded-full dark:bg-gray-300 w-full'></div>
                                </div>
                                <div className='flex items-center w-full space-x-2 max-w-[800px]'>
                                    <div className='h-2.5 bg-gray-300 rounded-full dark:bg-gray-300 w-full'></div>
                                    <div className='h-2.5 bg-gray-200 rounded-full dark:bg-gray-300 w-80'></div>
                                    <div className='h-2.5 bg-gray-300 rounded-full dark:bg-gray-300 w-full'></div>
                                </div>
                                <span className='sr-only'>Loading...</span>
                            </div>
                        </div>
                        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4'>
                            <div
                                role='status'
                                className='max-w-sm p-4 border border-gray-200 rounded shadow animate-pulse md:p-6 dark:border-gray-300'
                            >
                                <div className='flex items-center justify-center h-48 mb-4 bg-gray-300 rounded dark:bg-gray-300'>
                                    <svg>
                                        {
                                            loading ? (
                                                <path
                                                    fillRule='evenodd'
                                                    clipRule='evenodd'
                                                    d='M11.892 0H4a2 2 0 00-2 2v16a2 2 0 002 2h8a2 2 0 002-2V2a2 2 0 00-2-2zm-1 17h-6v-2h6v2zm0-4h-6v-2h6v2zm0-4h-6V7h6v2z'
                                                />
                                            ) : (
                                                <path
                                                    fillRule='evenodd'
                                                    clipRule='evenodd'
                                                    d='M8 0a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2V2a2 2 0 00-2-2h-8zm7 10h-6V4h6v6zm-6 2v6H2v-6h7zm7 0v6h-6v-6h6z'
                                                />
                                            )
                                        }
                                    </svg>
                                </div>
                                <div className='animate-pulse flex items-center justify-center h-8 mb-3 bg-gray-200 rounded w-3/4 dark:bg-gray-300'></div>
                                <div className='animate-pulse flex items-center justify-center h-8 mb-3 bg-gray-200 rounded w-3/4 dark:bg-gray-300'></div>
                                <div className='animate-pulse flex items-center justify-center h-8 mb-3 bg-gray-200 rounded w-3/4 dark:bg-gray-300'></div>
                                <div className='animate-pulse flex items-center justify-center h-8 mb-3 bg-gray-200 rounded w-3/4 dark:bg-gray-300'></div>
                            </div>
                        </div>
                    </>
                )}
            </div >
        </>
    );
};

export default ObjectElem;
