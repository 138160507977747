import { createSlice } from "@reduxjs/toolkit";
import {
  getAllArchivedParticipants,
  getAllParticipants,
} from "../thunks/participants";

const initialState = {
  data: null,
  status: "loading",
};

const participantsSlice = createSlice({
  name: "participants",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllParticipants.pending, (state) => {
        state.status = "loading";
        state.data = null;
      })
      .addCase(getAllParticipants.fulfilled, (state, action) => {
        state.status = "loaded";
        state.data = action.payload;
      })
      .addCase(getAllParticipants.rejected, (state) => {
        state.status = "error";
        state.data = null;
      })
      .addCase(getAllArchivedParticipants.pending, (state) => {
        state.status = "loading";
        state.data = null;
      })
      .addCase(getAllArchivedParticipants.fulfilled, (state, action) => {
        state.status = "loaded";
        state.data = action.payload;
      })
      .addCase(getAllArchivedParticipants.rejected, (state) => {
        state.status = "error";
        state.data = null;
      });
  },
});

export const participantsReducer = participantsSlice.reducer;
