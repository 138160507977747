import React, { useRef } from 'react'
import axios from '../libs/axios'

export default function UploadFile({
  setState,
  className,
  children,
  dir,
  isMultiple
}) {
  const inputFileRef = useRef(null);

  const handleChangeFile = async (e) => {
    try {
      const formData = new FormData();
      if(isMultiple) {
        const files = Array.from(e.target.files);
        files.forEach((file) => formData.append('images', file)); // Use 'images' to match backend field name
        const { data } = await axios.post(`/file/upload/multiple?dir=${dir}`, formData);
        setState(data.urls)
      }
      else{ 
        const file = e.target.files[0]
        formData.append('image', file);
        const { data } = await axios.post(`/file/upload?dir=${dir}`, formData);
        setState(data.url);
      }
    } catch (err) {
      console.warn(err);
    }
  }

  return (
    <div onClick={() => inputFileRef.current.click()} className={className}>
      <input ref={inputFileRef} multiple={isMultiple} hidden onChange={handleChangeFile} type='file' />
      {children}
    </div>
  )
}
