import React from 'react';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

const ExportToZip = ({ jsonData, className }) => {
    const handleExport = async () => {
        const zip = new JSZip();

        // Process each participant data
        for (const participant of jsonData) {
            // Create participant folder (name_lastname_personalNumber)
            const participantFolder = zip.folder(`${participant.name}_${participant.lastname}_${participant.personalNumber}`);

            // Add screens if available
            if (participant.screens) {
                // Iterate over screens and place them under appropriate stage folders
                for (const screen of participant.screens) {
                    const stageFolderName = `stage_${screen.stage}`;
                    let stageFolder = participantFolder.folder(stageFolderName);

                    // Only create stage folder if it doesn't exist
                    if (!stageFolder) {
                        stageFolder = participantFolder.folder(stageFolderName);
                    }

                    try {
                        const screenResponse = await fetch(`${process.env.REACT_APP_EXPRESS_URL}${screen.image}`);
                        if (!screenResponse.ok) {
                            throw new Error(`Failed to fetch screen ${screen.image} for ${participant.name}_${participant.personalNumber}`);
                        }
                        const screenBlob = await screenResponse.blob();
                        const imageName = screen.image.split('/').pop().replace('.', `___${screen.views} views.`);
                        stageFolder.file(imageName, screenBlob);
                    } catch (error) {
                        console.error(`Error fetching screen ${screen.image} for ${participant.name}_${participant.personalNumber}:`, error);
                    }
                }
            }
        }

        // Generate and save the ZIP file
        zip.generateAsync({ type: 'blob' }).then((content) => {
            saveAs(content, 'screens.zip');
        });
    };

    return (
        <button onClick={handleExport} className={`btn min-w-full bg-purple-500 text-white ${className}`}>
            .zip ფაილში დაექპსორტება
        </button>
    );
};

export default ExportToZip;
