import React, { forwardRef } from "react";
import { Link } from "react-router-dom";

const Checkbox = forwardRef((props, ref) => {
    const { label, ...rest } = props;
    return (
        <div className="flex items-center gap-2">
            <input
                ref={ref}
                type="checkbox"
                className="checkbox"
                {...rest}
            />
            <label className="text-lg font-medium text-gray-900">
                <span className="text-base">
                    <Link to="/terms" target="_blank" className="text-blue-600 hover:text-blue-800 hover:underline">
                        {label}
                    </Link>
                </span>
            </label>
        </div>
    );
});

Checkbox.displayName = 'Checkbox';

export default Checkbox;
