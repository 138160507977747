import { createSlice } from "@reduxjs/toolkit";
import { getAllObjects } from "../thunks/objectThunks";

const initialState = {
  data: null,
  status: "loading",
};

const objectsSlice = createSlice({
  name: "objects",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllObjects.pending, (state) => {
        state.status = "loading";
        state.data = null;
      })
      .addCase(getAllObjects.fulfilled, (state, action) => {
        state.status = "loaded";
        state.data = action.payload;
      })
      .addCase(getAllObjects.rejected, (state) => {
        state.status = "error";
        state.data = null;
      });
  },
});

export const objectsReducer = objectsSlice.reducer;
