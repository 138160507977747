import React, { useEffect, useState } from 'react'
import UploadVideo from './vid/UploadVideo'
import { useDispatch, useSelector } from 'react-redux';
import { fetchContentVideo } from '../../../redux/thunks/content';
import { addVideo } from '../../../redux/slices/content';
import axios from "../../../libs/axios"
import toast from 'react-hot-toast';

const MainPage = () => {
    const dispatch = useDispatch();
    const videoUrl = useSelector((state) => state.content);
    const isLoading = videoUrl.status !== 'loaded';
    
    const [allYear, setAllYear] = useState();
    const [year, setYear] = useState(null)
    
    useEffect(() => {
        axios.get('/archive')
            .then(res => setAllYear(res.data))
            .catch(err => toast.error('არქივი ვერ მოიძებნა; დაფიქსირდა შეცდომა'))
    }, []);
    
    useEffect(() => {
        dispatch(fetchContentVideo())
    }, [dispatch])
    
    const onSaveYear = () => {
        axios.post('/content/winnerYear', {
            year: year
        }).then((res) => {
            toast.success("გამარჯვებულების წელი წარმატებით შეიცვალა")
        }).catch((err) => {
            toast.error("დაფიქსიდა შეცდომა")
        })
    }

    const handleVideoUpload = (newVideoUrl) => {
        dispatch(addVideo(newVideoUrl))
        window.location.reload()
    }

    return (
        <div className='flex flex-col px-2 sm:px-10 gap-5'>
            <h1 className='text-3xl text-center mt-4'>მთავარი გვერდი</h1>
            <div className='card p-5 shadow-xl w-full bg-white h-fit box-border'>
                <h1 className='text-2xl ml-4 mt-2'>ვიდეო-რგოლის რედაქტირება:</h1>
                <div className='divider' />
                <UploadVideo onUpload={handleVideoUpload}/>
                <div className='relative w-full h-[30dvh] sm:h-[60dvh] py-4 mt-5 box-border'>
                    <div className="absolute left-0 top-0 video-docker w-full h-full overflow-hidden">
                        {(!isLoading || videoUrl.video) && (
                            <video
                                className="min-w-full min-h-full object-cover"
                                src={process.env.REACT_APP_EXPRESS_URL + videoUrl.video}
                                muted
                                loop
                                autoPlay
                            >
                            </video>
                        )}
                    </div>
                </div>
            </div>
            <div className='card p-5 shadow-xl w-full bg-white h-fit box-border'>
                <h1 className='text-2xl ml-4 mt-2'>გამარჯვებულების გამოტანა წლის მიხედვით</h1>
                <div className='divider' />
                <div className='flex flex-col gap-4'>
                    <select onChange={(e) => setYear(e.target.value)}>
                        <option>აირჩიეთ წელი</option>
                        {allYear?.map((year, i) => 
                            <option key={i}>{year.date.split('-')[0]}</option>
                        )}
                        <option>2023</option>
                    </select>
                    <button
                        className={`bg-green-500 btn text-white rounded-lg mb-5`}
                        onClick={() => onSaveYear()}
                    >
                        შენახვა
                    </button>
                </div>
            </div>
        </div>
    )
}

export default MainPage
