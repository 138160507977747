// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./FiraGO-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./Adaptirebuli_1.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./geo-gza.woff", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: 'FiraGO';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Adaptirebuli';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('opentype');
    font-display: swap;
}

@font-face {
    font-family: 'GeoGza';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('woff');
    font-display: swap;
}

/* Define CSS variables for the fonts */
:root {
    --font-fira-go: 'FiraGO', sans-serif;
    --font-adaptirebuli: 'Adaptirebuli', sans-serif;
    --font-geo-gza: 'GeoGza', sans-serif;
}`, "",{"version":3,"sources":["webpack://./src/fonts/fonts.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,+DAAmD;IACnD,kBAAkB;AACtB;;AAEA;IACI,2BAA2B;IAC3B,+DAAmD;IACnD,kBAAkB;AACtB;;AAEA;IACI,qBAAqB;IACrB,2DAAyC;IACzC,kBAAkB;AACtB;;AAEA,uCAAuC;AACvC;IACI,oCAAoC;IACpC,+CAA+C;IAC/C,oCAAoC;AACxC","sourcesContent":["@font-face {\n    font-family: 'FiraGO';\n    src: url('./FiraGO-Regular.ttf') format('truetype');\n    font-display: swap;\n}\n\n@font-face {\n    font-family: 'Adaptirebuli';\n    src: url('./Adaptirebuli_1.otf') format('opentype');\n    font-display: swap;\n}\n\n@font-face {\n    font-family: 'GeoGza';\n    src: url('./geo-gza.woff') format('woff');\n    font-display: swap;\n}\n\n/* Define CSS variables for the fonts */\n:root {\n    --font-fira-go: 'FiraGO', sans-serif;\n    --font-adaptirebuli: 'Adaptirebuli', sans-serif;\n    --font-geo-gza: 'GeoGza', sans-serif;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
