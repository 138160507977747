// const colors = [
//     'bg-orange-100',
//     'bg-blue-100',
//     'bg-green-100',
//     'bg-red-100',
//     'bg-pink-100',
//     'bg-cyan-100',
//     'bg-purple-100'
// ];

// const PlaceCard = ({ id, title, description, list }) => {

//     return (
//         <>
//             <a href={`#my_modal_${id}`} className={`card text-center w-full md:w-1/4 lg:w-1/5 ${colors[id % colors.length]}`}>
//                 <div className="card-body justify-center items-center">
//                     <h2 className="card-title my-0">{title}</h2>
//                 </div>
//             </a>

//             <div className="modal" id={`my_modal_${id}`}>
//                 <form method="dialog" className="modal-box w-11/12 max-w-5xl">
//                     <div dangerouslySetInnerHTML={{ __html: description }} className="text-black" />
//                     {list && <ul className="w-full">
//                         {list?.map((item, _index) => (<li className="w-full break-words" key={_index}>{item?.name && item?.name + ' : '}  {item.link && <a target="_blank" className="underline" rel="noreferrer" href={item?.link}>{item?.link}</a>}</li>))}
//                     </ul>}
//                     <div className="modal-action">
//                         <a href="#" className="btn">დახურვა</a>
//                     </div>
//                 </form>
//             </div>
//         </>
//     );
// };

// export default PlaceCard;


import { useState } from "react";
import clsx from 'clsx';

const colors = [
    'bg-orange-100',
    'bg-blue-100',
    'bg-green-100',
    'bg-red-100',
    'bg-pink-100',
    'bg-cyan-100',
    'bg-purple-100'
];

const PlaceCard = ({ id, title, description, list }) => {
    const [isOpen, setOpen] = useState(false);
    return (
        <>
            <button onClick={() => setOpen(!isOpen)} className={`card flex justify-center items-center text-center w-full sm:w-1/4 md:w-2/5 xl:w-1/5 ${colors[id % colors.length]}`}>
                <div className="card-body justify-center items-center">
                    <h2 className="card-title my-0">{title}</h2>
                </div>
            </button>

            <div className={clsx(
                "fixed left-0 z-50 w-full h-full justify-center items-center bg-black/[19%] top-0",
                isOpen ? "modalActive flex" : "hidden",
            )}>
                <form method="dialog" className="modal-box w-11/12 max-w-5xl">
                    <div dangerouslySetInnerHTML={{ __html: description }} className="text-black" />
                    {list && <ul className="w-full  list-disc">
                        {list?.map((item, _index) => (
                            <li className="w-full break-words ml-5" key={_index}>
                                {item?.link 
                                    ? <a target="_blank" className="underline" rel="noreferrer" href={item?.link}>{item?.name && item?.name}</a>
                                    : <p>{item?.name && item?.name}</p>
                                }
                            </li>
                        ))}
                    </ul>}
                    <div className="modal-action">
                        <button onClick={() => setOpen(!isOpen)} className="btn">დახურვა</button>
                    </div>
                </form>
            </div>
        </>
    );
};

export default PlaceCard;
