import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../libs/axios";

export const fetchParticipantAuth = createAsyncThunk(
  "participant/fetchParticipantAuth",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(`/participant/login`, params);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchAuthMe = createAsyncThunk(
  "auth/fetchAuthMe",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get("/current");
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchParticipantRegister = createAsyncThunk(
  "participant/fetchParticipantRegister",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(`/participant/register`, params);
      console.log(params);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchAdminAuth = createAsyncThunk(
  "admin/fetchadminAuth",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(`/admin/login`, params);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchObjectAuth = createAsyncThunk(
  "object/fetchObjectAuth",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(`/object/login`, params);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchObjectRegister = createAsyncThunk(
  "object/fetchObjectRegister",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(`/object/register`, params);
      console.log(params);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchObjectEdit = createAsyncThunk(
  "object/fetchObjectEdit",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(`/object/edit`, params);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchParticipantEdit = createAsyncThunk(
  "object/fetchParticipantEdit",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(`/participant/edit`, params);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
