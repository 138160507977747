
const Instagram = () => {
    // useEffect(() => {
    //     const script = document.createElement('script');
    //     script.src = 'https://widgets.sociablekit.com/instagram-hashtag-feed/widget.js';
    //     script.async = true;
    //     script.defer = true;
    //     document.body.appendChild(script);

    //     return () => {
    //         document.body.removeChild(script);
    //     };
    // }, []);

    // return (
    //     <>
    //         <div className='sk-ww-instagram-hashtag-feed w-[80%]' data-embed-id='25428980'></div>
    //     </>
    // );
    return <iframe src='https://widgets.sociablekit.com/instagram-hashtag-feed/iframe/25428980' title='instagram' frameborder='0' width='100%' height='1000'></iframe>
};

export default Instagram;
