import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Input from '../formComponents/Input';
import { fetchObjectAuth } from '../../../redux/thunks/authThunks';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

const ObjLoginForm = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const schema = yup.object().shape({
        email: yup.string().email('Invalid email').required('Email is required'),
        password: yup.string().required('Password is required'),
    });

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const dispatch = useDispatch();

    const onSubmit = async (val) => {
        const data = await dispatch(fetchObjectAuth({
            email: val.email,
            password: val.password,
        }));
        if (!data.payload) {
            setLoading(false)
        } else {
            if ('token' in data.payload) {
                window.localStorage.setItem('token', data.payload.token);
                navigate("/object-dashboard")
            } else {

            }
            setLoading(false);
        }
    };

    return (
        <div className="container mx-auto">
            <form className="space-y-4 my-4" onSubmit={handleSubmit(onSubmit)}>
                <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                        <Input
                            {...field}
                            label="Email"
                            placeholder="Enter your email"
                            error={errors.email?.message}
                        />
                    )}
                />
                <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                        <Input
                            {...field}
                            label="Password"
                            type="password"
                            placeholder="Enter your password"
                            error={errors.password?.message}
                        />
                    )}
                />
                <div className="flex items-center justify-end">
                    <Link to='/object/reset-password' className="self-end hover:text-blue-800 hover:underline">დაგავიწყდათ პაროლი?</Link>
                </div>
                <button
                    type="submit"
                    className="btn btn-block btn-primary"
                    disabled={loading}
                >
                    {loading && <span className="loading loading-spinner"></span>}
                    შესვლა
                </button>
            </form>
            <span className="mt-8">
                არ გაქვთ აქაუნთი?{' '}
                <a
                    href="/register"
                    className="text-blue-600 hover:text-blue-800 hover:underline"
                >
                    რეგისტრაცია
                </a>
            </span>
        </div>
    );
};

export default ObjLoginForm;
