import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getAllArchivedParticipants, getAllParticipants } from '../../../redux/thunks/participants';
import supabase from '../../../libs/supabase';
import axios from "../../../libs/axios"
import toast from 'react-hot-toast';
import { getYearOnly } from '../../../utils/getYearOnly';
import clsx from 'clsx';

const PastWinnersIds = [
    'd3e9966c-59f2-4aed-bcfc-9bcd4c86b2ab',
    '0af9a955-208c-4a9e-975e-d9a759a79006',
    'ce19da05-95e0-4234-ac8a-471cebd631c3',
    '8d7b572f-eb26-4758-af3c-7cef9caa5ae6',
    'fc15242e-4932-4afb-97f8-0da92ddc7316',
    '567df4ca-805c-4fc5-9253-90431f99fa83'
];

const HiddenParticipants = () => {
    const [selectedYear, setSelectedYear] = useState(0);
    const [years, setYears] = useState([]);
    const dispatch = useDispatch();
    const participants = useSelector((state) => state.participants);

    useEffect(() => {
        axios.get('/archive')
            .then(res => setYears(res.data))
            .catch(err => toast.error('არქივი ვერ მოიძებნა; დაფიქსირდა შეცდომა'))
    }, []);



    useEffect(() => {
        if (selectedYear === 0) {
            dispatch(getAllParticipants({ hidden: true }))
        } else {
            dispatch(getAllArchivedParticipants({ date: selectedYear.toString() }))
        }
    }, [dispatch, selectedYear]);

    const handleYearChange = (year) => {
        setSelectedYear(year);
    };

    const handleToggleStage = async (user) => {
        const newStage = user.stage === 1 ? 2 : 1;
        axios.post('/admin/stage', { userId: user._id, stage: newStage })
            .then(res => {
                if (res.status === 200) {
                    toast.success('მომხმარებლის ეტაპი შეიცვალა');
                    dispatch(getAllParticipants({ hidden: true }))
                }
            })
            .catch(err => toast.error(err.response?.data?.message));
    };

    return (
        <div className="container mx-auto my-4 px-7">
            <div className="flex justify-center space-x-4 mb-4">
                <button
                    className={`btn ${selectedYear === 0 ? 'btn-primary' : 'btn-neutral'}`}
                    onClick={() => handleYearChange(0)}
                >
                    ახლა
                </button>
                {years?.map((year, _index) => {
                    if (getYearOnly(year.date) === 2023) return null;
                    return (<button
                        key={_index}
                        className={`btn ${selectedYear === getYearOnly(year.date) ? 'btn-primary' : 'btn-neutral'}`}
                        onClick={() => handleYearChange(getYearOnly(year.date))}
                    >
                        {getYearOnly(year.date)}
                    </button>)
                })}
            </div>
            <div className="mt-5 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
                {participants.data?.map((user, i) => (
                    <UserCard
                        key={i}
                        user={user}
                        handleToggleStage={handleToggleStage}
                        isArchived={selectedYear !== 0}
                    />
                ))}
            </div>
        </div>
    );
};

const UserCard = ({ user, isWinner, handleToggleStage, isPast, isArchived }) => {
    const [showModal, setShowModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);

    const handleDeleteClick = (user) => {
        console.log('ae');
        setSelectedUser(user);
        setShowModal(true);
    };

    const handleCancel = () => {
        setSelectedUser(null);
        setShowModal(false);
    }
    
    const handleHideClick = async (id) => {
        try {
            console.log(id);
            await axios.post('/participant/hide', { userId: id });
            window.location.href = "/admin/hidden"
            toast.success('მომხმარებელის სტატუსი წარმატებით შეიცვალა!')
        } catch (error) {
            console.error('Failed to delete user:', error);
        }
    }

    const handleConfirmDelete = async () => {
        try {
            await axios.post('/participant/delete', { userId: selectedUser._id });
            // Handle successful deletion (e.g., remove user from the list, show a success message, etc.)
            setShowModal(false);
            setSelectedUser(null);
            toast.success('მომხმარებელი წარმატებით წაიშალა!')
            window.location.href = "/admin/hidden"
        } catch (error) {
            console.error('Failed to delete user:', error);
            // Handle error (e.g., show an error message)
        }
    };

    return (
        <div>
            <div className="flex flex-col relative sm:flex-row bg-white shadow-md p-4 rounded-lg">
                {!isArchived && (
                    <>
                        <span
                            onClick={() => handleDeleteClick(user)}
                            className='mt-[-5px] mr-[-5px] w-[10px] h-[10px] rounded-full bg-red-500 text-white cursor-pointer p-3 flex items-center justify-center'>
                            x
                        </span>
                        <span
                            onClick={() => handleHideClick(user._id)}
                            className={clsx(
                                'mt-[-5px] absolute right-3 mr-[-5px] h-[10px] rounded-full bg-green-500 text-white cursor-pointer p-3 flex items-center justify-center',
                                user.isHidden && "bg-blue-600"
                            )}>
                            unhide
                        </span>
                    </>
                )}
                {isPast ?
                    <img src={`${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/profiles/${user.avatar_url}.webp`} alt={user.name} className="w-full sm:w-24 h-24 rounded-full object-cover mb-4 sm:mb-0 sm:mr-4" />
                    :
                    <img src={`${process.env.REACT_APP_EXPRESS_URL}${user.avatar_url}`} alt={user.name} className="w-full sm:w-24 h-24 rounded-full object-cover mb-4 sm:mb-0 sm:mr-4" />
                }
                <div className="flex flex-col justify-between flex-grow">
                    <div>
                        <h2 className="text-xl font-bold">{user.name} {user.surname}</h2>
                        <p>{user.email}</p>
                        {user.tour?.id ? <p>პაკეტი: {user.tour?.id}</p> : ""}
                        {user.tel && <p>T: {user.tel}</p>}
                        <div className="flex space-x-2 mt-2">
                            {isPast ? (
                                <>
                                    {user.fb && <a href={user.fb} className="text-blue-600">FB</a>}
                                    {user.yt && <a href={user.yt} className="text-red-600">YT</a>}
                                    {user.ig && <a href={user.ig} className="text-pink-600">IG</a>}
                                    {user.tk && <a href={user.tk} className="text-black">TK</a>}
                                </>
                            ) : (
                                <>
                                    {user.social?.fb && <a href={user.social?.fb} className="text-blue-600">FB</a>}
                                    {user.social?.yt && <a href={user.social?.yt} className="text-red-600">YT</a>}
                                    {user.social?.ig && <a href={user.social?.ig} className="text-pink-600">IG</a>}
                                    {user.social?.tk && <a href={user.social?.tk} className="text-black">TK</a>}
                                </>
                            )}
                        </div>
                    </div>
                    <div className="flex justify-between items-center mt-4">
                        {(!isPast && !isArchived) && <button onClick={() => handleToggleStage(user)} className="btn btn-secondary">
                            Stage: {user.stage}
                        </button>}
                        {isWinner && <span className="badge badge-success">Winner</span>}
                    </div>
                </div>
            </div>

            {showModal && (
                <div className='fixed px-5 inset-0 flex justify-center items-center z-50 bg-black bg-opacity-50'>
                    <div className='p-5 rounded-md bg-white box-border z-10'>
                        <p className='text-center'>დარწმუნებული ხართ რომ გსურთ წაშალოთ მონაწილე {selectedUser.name} {selectedUser.lastname}?</p>
                        <div className="flex justify-end space-x-4 mt-4">
                            <button onClick={handleCancel} className="btn btn-secondary">გაუქმება</button>
                            <button onClick={handleConfirmDelete} className="btn bg-red-500 text-white">წაშლა</button>
                        </div>
                    </div>
                </div>
                // <div className="modal flex items-center justify-centerF">
                //     <div className="bg-white p-6 rounded-lg shadow-lg">
                //         <h2 className="text-xl font-bold mb-4">Delete {selectedUser.name} {selectedUser.surname}?</h2>
                //         <p>Are you sure you want to delete this participant?</p>
                //         <div className="flex justify-end space-x-4 mt-4">
                //             <button onClick={() => setShowModal(false)} className="btn btn-secondary">Cancel</button>
                //             <button onClick={handleConfirmDelete} className="btn btn-danger">Delete</button>
                //         </div>
                //     </div>
                // </div>
            )}
        </div>
    );
};
export default HiddenParticipants;
