import * as yup from 'yup';

const georgianRegex = /^[ა-ჰ]+$/;

const registrationSchema = yup.object().shape({
    email: yup
        .string()
        .email('გთხოვთ, შეიყვანოთ ვალიდური ელ.ფოსტის მისამართი')
        .required('ელ.ფოსტა აუცილებელია'),
    password: yup
        .string()
        .min(8, 'პაროლი უნდა შეიცავდეს მინიმუმ 8 სიმბოლოს')
        .required('პაროლი აუცილებელია'),
    name: yup.string().required('სახელი აუცილებელია').matches(georgianRegex, 'სახელი მხოლოდ ქართული ასოებით'),
    lastname: yup.string().required('გვარი აუცილებელია').matches(georgianRegex, 'გვარში მხოლოდ ქართული ასოებით'),
    tel: yup.string().required('ტელეფონი აუცილებელია'),
    personalNumber: yup
        .string()
        .length(11, 'პირადი ნომერი უნდა შედგებოდეს 11 სიმბოლოსგან')
        .required('პირადი ნომერი აუცილებელია'),
    birthday: yup
        .string()
        .required('დაბადების თარიღი აუცილებელია')
        .typeError('გთხოვთ, შეიყვანოთ ვალიდური თარიღი'),
    avatar_url: yup.string().required('ავატარის URL აუცილებელია'),
    fb: yup
        .string()
        .url('მიუთითეთ ვალიდური URL')
        .transform((value, originalValue) => {
            if (!value?.startsWith('https://www') && value?.toLowerCase()?.startsWith('facebook.com')) {
                return `https://www.${value}`;
            }
            return value;
        })
        .nullable(),
    ig: yup
        .string()
        .url('მიუთითეთ ვალიდური URL')
        .transform((value, originalValue) => {
            if (!value?.startsWith('https://www') && value?.toLowerCase()?.startsWith('instagram.com')) {
                return `https://www.${value}`;
            }
            return value;
        })
        .nullable(),
    tk: yup
        .string()
        .url('მიუთითეთ ვალიდური URL')
        .transform((value, originalValue) => {
            if (!value?.startsWith('https://www') && value?.toLowerCase()?.startsWith('tiktok.com')) {
                return `https://www.${value}`;
            }
            return value;
        })
        .nullable(),
    yt: yup
        .string()
        .url('მიუთითეთ ვალიდური URL')
        .transform((value, originalValue) => {
            if (!value?.startsWith('https://www') && value?.toLowerCase()?.startsWith('youtube.com')) {
                return `https://www.${value}`;
            }
            return value;
        })
        .nullable(),
    rules: yup
        .boolean()
        .oneOf([true], 'თქვენ უნდა დაეთანხმოთ წესებსა და პირობებს')
        .required('თქვენ უნდა დაეთანხმოთ წესებსა და პირობებს'),
})

export default registrationSchema;
