import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../libs/axios";

export const fetchContentTerms = createAsyncThunk(
  "content/fetchContentTerms",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get("/content/terms");
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);


export const fetchContentAbout = createAsyncThunk(
    "content/fetchContentAbout",
    async (_, { rejectWithValue }) => {
      try {
        const { data } = await axios.get("/content/about");
        return data;
      } catch (err) {
        return rejectWithValue(err.response.data);
      }
    }
  );
  

  export const fetchContentVideo = createAsyncThunk(
    "content/fetchContentVideo",
    async (_, { rejectWithValue }) => {
      try {
        const { data } = await axios.get("/content/video");
        return data;
      } catch (err) {
        return rejectWithValue(err.response.data);
      }
    }
  );