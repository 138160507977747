import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../libs/axios";

export const getAllObjects = createAsyncThunk(
  "participants/getAllObjects",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get("/object");
      console.log(data);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
