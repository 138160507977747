import React from 'react';
import { Link, Outlet } from 'react-router-dom';

const AdminPanel = () => {
    const navItems = [
        {
            id: 0,
            title: 'სტატისტიკა',
            icon: '',
            link: 'statistics'
        },
        {
            id: 1,
            title: 'მთავარი გვერდი',
            icon: '',
            link: 'main'
        },
        {
            id: 2,
            title: 'ჩვენს შესახებ',
            icon: '',
            link: 'about'
        },
        {
            id: 3,
            title: 'პირობები',
            icon: '',
            link: 'terms'
        },
        {
            id: 4,
            title: 'მონაწილეები',
            icon: '',
            link: 'participants'
        },
        {
            id: 5,
            title: 'გათიშული მონაწილეები',
            icon: '',
            link: 'hidden'
        },
        {
            id: 6,
            title: 'პაკეტები',
            icon: '',
            link: 'tours'
        },
        {
            id: 7,
            title: 'არქივი',
            icon: '',
            link: 'archive'
        },
    ]

    return (
        <div className="flex flex-col sm:flex-row min-h-[80dvh]">
            <div className="h-fit sm:h-full w-full sm:w-[25%] bg-white sm:flex sm:flex-col">
                <ul className="sm:block flex flex-row flex-wrap justify-around text-sm sm:text-xl sm:pt-10">
                    {navItems.map((item) => (
                        <li key={item.id} className="w-[50%] sm:w-full md:text-2xl flex items-center cursor-pointer hover:bg-gray-100">
                            <Link to={item.link} className='w-full h-full py-2 sm:py-4 px-4 sm:px-8'>{item.title}</Link>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="h-full w-full sm:w-[75%]">
                <Outlet />
            </div>
        </div>
    );
};

export default AdminPanel;
