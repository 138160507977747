import axios from "../../../libs/axios";
import toast from "react-hot-toast";
import clsx from 'clsx';
import editIcon from "../../../assets/icons/edit.svg"
import { useEffect, useState } from "react";

const ToursAdmin = () => {
    const [tours, setTours] = useState([]);
    const [pageLoad, setPageLoad] = useState(true);
    const [tourModal, setTourModal] = useState(false);
    const [expandedTourId, setExpandedTourId] = useState(null);
    const [place, setPlace] = useState(null);
 
    const handleAccordionClick = (id) => {
        setExpandedTourId(expandedTourId === id ? null : id);
    };

    useEffect(() => {
        axios.get('/tour').then((res) => {
            setTours(res.data);
            setPageLoad(false)
        }).catch(() => {
            toast.error("Cannot get tours")
            setPageLoad(false)
        })
    }, [])

    const handleSave = () => {
        setPageLoad(true);
        axios.post('/tour/edit', {
            tours: tours
        }).then((res) => {
            toast.success("Tour has been updated")
            setPageLoad(false);
        }).catch((err) => {
            toast.error("Something went wrong")
            setPageLoad(false)
        })
    }

    const appendPlace = () => {
        setTours(prev => {
            return prev.map(tour => {
                if (tour.id === expandedTourId) {
                    return { ...tour, places: [...tour.places, { title: "", description: "", list: [] }] };
                }
                return tour;
            });
        });
    };

    const removePlace = (placeId) => {
        setTours(prev => {
            return prev.map(tour => {
                if (tour.id === expandedTourId) {
                    const updatedPlaces = tour.places.filter((_, index) => index !== placeId);
                    return { ...tour, places: updatedPlaces };
                }
                return tour;
            });
        });
    };

    const updateTours = (tourId, placeId, updatedPlace) => {
        setTours(prev => {
            return prev.map(tour => {
                if (tour.id === tourId) {
                    const updatedPlaces = tour.places.map((place, index) => {
                        if (index === placeId) {
                            return { ...place, ...updatedPlace };
                        }
                        return place;
                    });
                    return { ...tour, places: updatedPlaces };
                }
                return tour;
            });
        });
    };

    if(pageLoad) return <div className="w-full h-[70dvh] flex justify-center items-center">Loading...</div>;

    return (
        <>
            <PlaceModal
                place={place}
                setTours={updateTours}
                onClose={() => setTourModal(false)}
                isOpen={tourModal}
            />
            <div className="flex px-4 flex-col gap-5 py-2">
                <button onClick={() => handleSave()} className="w-full py-2 bg-blue-300 rounded-full">Save</button>
            </div>
            <div className="flex px-4 flex-col gap-5 py-2">
                {tours.map((tour, tourIndex) => (
                    <div key={tour.id} className="mb-4 border rounded shadow-sm w-full">
                        <div
                            className="cursor-pointer bg-gray-200 p-4"
                            onClick={() => handleAccordionClick(tour.id)}
                        >
                            <h3 className="text-lg font-medium">პაკეტი {tour.id}</h3>
                        </div>
                        {expandedTourId === tour.id && (
                            <div className="p-4 bg-white flex flex-col justify-start items-start md:flex-row relative gap-4">
                                <button onClick={() => appendPlace()} className="bg-blue-500 w-full md:w-auto text-white py-2 md:h-[200px] top-[50%] left-0 block md:sticky px-2 rounded-md">+</button>
                                <div className="flex flex-col justify-start items-center w-full gap-2">
                                    <h2 className="text-center">ლოკაციები</h2>
                                    <div className="grid w-full gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
                                        {tour.places?.map((place, placeId) => (
                                            <PlaceCardAdmin remove={() => removePlace(placeId)} id={placeId} key={placeId} onOpen={() => {
                                                setTourModal(true);
                                                setPlace({ ...place, tourId: tour.id, placeId });
                                            }} title={place.title} />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </>
    )
}

const PlaceModal = ({ place, onClose, isOpen, setTours }) => {
    const [editing, setEditing] = useState({
        title: false,
        description: false,
        list: -1
    });
    const [currentPlace, setCurrentPlace] = useState(place);

    useEffect(() => {
        setCurrentPlace(place);
    }, [place]);

    if (!currentPlace) return null;

    const savePlaceDetails = (field, value) => {
        const updatedPlace = { [field]: value };
        setTours(currentPlace.tourId, currentPlace.placeId, updatedPlace);
        setCurrentPlace((prev) => ({ ...prev, ...updatedPlace }));
    };

    const addNewListItem = () => {
        const updatedList = [...currentPlace.list, {name: "", link: ""}];
        savePlaceDetails('list', updatedList);
    };

    return (
        <div className={clsx(
            "fixed justify-center items-center left-0 top-0 w-full bg-black/[19%] z-50 h-dvh",
            isOpen ? "flex modalActive" : "hidden"
        )}>
            <div className="w-full rounded-2xl sm:w-3/4 h-2/4 bg-white">
                <div className={clsx(
                    "flex gap-3 justify-center relative items-center w-full",
                    editing.title ? "py-7" : "py-3 "
                )}>
                    {editing.title
                        ? <Editing value={currentPlace.title} onSave={(value) => {
                            setEditing((prev) => ({ ...prev, title: false }));
                            savePlaceDetails('title', value);
                        }} />
                        : (
                            <>
                                <h2 className="text-2xl">{currentPlace?.title ? currentPlace?.title : "სათაური"}</h2>
                                <button onClick={() => setEditing((prev) => ({ ...prev, title: true }))}>
                                    <img src={editIcon} alt="edit" width={20} height={20} />
                                </button>
                            </>
                        )
                    }
                    <button className="absolute right-3 top-1" onClick={() => {
                        onClose();
                        setEditing({
                            title: false,
                            description: false,
                            list: -1
                        })
                    }}>X</button>
                </div>
                <div className="divider !my-0"></div>
                <div className="bg-white flex flex-col w-full h-full">
                    <div className="w-full relative pb-5">
                        <h2 className="text-center mb-1 text-xl">აღწერა</h2>
                        {editing.description
                            ? <Editing value={currentPlace.description} onSave={(value) => {
                                setEditing((prev) => ({ ...prev, description: false }));
                                savePlaceDetails('description', value);
                            }} />
                            : (
                                <>
                                    <p className="text-center text-sm">{currentPlace?.description}</p>
                                    <button onClick={() => setEditing((prev) => ({ ...prev, description: true }))} className="absolute right-2">
                                        <img src={editIcon} alt="edit" width={20} height={20} />
                                    </button>
                                </>
                            )
                        }
                    </div>
                    <div className="divider !my-0"></div>
                    <div className="w-full px-8">
                        <button className="w-full text-white py-2 bg-blue-600 rounded-lg mb-5" onClick={() => addNewListItem()}>Add</button>
                    </div>
                    <ul className="list-disc overflow-y-auto flex flex-col gap-5 px-8">
                        {currentPlace?.list?.map((list, i) =>
                            <li className="break-words p-2 bg-gray-100 rounded-lg relative " key={i}>
                                {editing.list === i
                                    ? (
                                        <EditList onSave={(value) => {
                                            setEditing((prev) => ({ ...prev, list: -1 }));
                                            const updatedList = [...currentPlace.list];
                                            updatedList[i] = value;
                                            savePlaceDetails('list', updatedList);
                                        }} name={currentPlace?.list[i]?.name} link={currentPlace?.list[i]?.link} />
                                    )
                                    : (
                                        <div className="flex w-full justify-between">
                                            {(!list.name && !list.link)
                                                ? (
                                                    <div>სახელი (ლინკი)</div>
                                                ) 
                                                : <a target="_blank" rel="noreferrer" href={list?.link}>{list?.name ? list.name : list.link}</a>
                                            }
                                            <div className="flex justify-center items-center gap-5">
                                                <button onClick={() => setEditing((prev) => ({ ...prev, list: i }))}>
                                                    <img src={editIcon} alt="edit" width={20} height={20} />
                                                </button>
                                                <button
                                                    onClick={() => {
                                                        const updatedList = [...currentPlace.list];
                                                        updatedList.splice(i, 1);
                                                        savePlaceDetails('list', updatedList);
                                                    }}
                                                >
                                                    X
                                                </button>
                                            </div>
                                        </div>
                                    )
                                }
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </div>
    )
}

const EditList = ({ name, link, onSave }) => {
    const [editedValue, setEditedValue] = useState({ name: name, link: link });
    return (
        <div className="flex flex-col gap-4">
            <label className="w-full flex gap-2 flex-col">
                სახელი
                <input onChange={(e) => setEditedValue((prev) => ({ ...prev, name: e.target.value }))} className="px-2 py-1 rounded-lg border outline-none" value={editedValue.name} />
            </label>
            <label className="w-full flex gap-2 flex-col">
                ლინკი
                <input onChange={(e) => setEditedValue((prev) => ({ ...prev, link: e.target.value }))} className="px-2 py-1 rounded-lg border outline-none" value={editedValue.link} />
            </label>
            <button onClick={() => onSave(editedValue)}>Save</button>
        </div>
    )
}

const Editing = ({ value, onSave }) => {
    const [editedValue, setEditedValue] = useState(value);
    return (
        <div className="flex w-full flex-col px-5 justify-center items-center gap-2">
            <textarea className="w-full px-2 py-1 outline-none border rounded-lg min-h-[50px] max-h-[100px]" value={editedValue} onChange={(e) => setEditedValue(e.target.value)} />
            <button className="px-2 py-1 bg-gray-200 rounded-full" onClick={() => onSave(editedValue)}>Save</button>
        </div>
    )
}

const PlaceCardAdmin = ({ onOpen, id, title, remove }) => {
    const colors = [
        'bg-orange-100',
        'bg-blue-100',
        'bg-green-100',
        'bg-red-100',
        'bg-pink-100',
        'bg-cyan-100',
        'bg-purple-100'
    ];

    return (
        <div className="relative w-full h-full">
            <button onClick={onOpen} className={`card flex justify-center items-center text-center h-full w-full ${colors[id % colors.length]}`}>
                <div className="card-body justify-center items-center">
                    <h2 className="card-title my-0">{title ? title : "სახელი"}</h2>
                </div>
            </button>
            <button className="absolute top-1 shadow-xl right-2 bg-white rounded-full px-2 py-1" onClick={remove}>Remove</button>
        </div>
    );
};

export default ToursAdmin;