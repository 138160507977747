import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import Input from '../../../Forms/formComponents/Input';
import axios from "../../../../libs/axios"
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

const UpdatePassword = () => {
    const { control, handleSubmit, formState: { errors } } = useForm();
    const navigate = useNavigate();
    const onSubmit = (data) => {
        axios.post(`/participant/update-password`, { oldPassword: data.oldPassword, newPassword: data.newPassword })
            .then(res => {
                toast.success('თქვენ წარმატებით შეცვალეთ პაროლი!')
                navigate('/profile')
            })
            .catch(err => toast.error('დაფიქსირდა შეცდომა'))
    };


    return (
        <div className="max-w-md mx-auto">
            <form className='space-y-4 my-4' onSubmit={handleSubmit(onSubmit)}>
                <Controller
                    name="oldPassword"
                    control={control}
                    rules={{
                        required: 'პაროლი აუცილებელია',
                        minLength: {
                            value: 8,
                            message: 'პაროლი უნდა შეიცავდეს მინიმუმ 8 სიმბოლოს'
                        }
                    }}
                    render={({ field }) => (
                        <Input
                            {...field}
                            label="ძველი პაროლი"
                            type="password"
                            placeholder="შეიყვანეთ ძველი პაროლი"
                        />
                    )}
                />
                {errors.oldPassword && <span className="text-red-700 text-sm mt-2">{errors.oldPassword.message}</span>}

                <Controller
                    name="newPassword"
                    control={control}
                    rules={{
                        required: 'შეიყვანეთ ახალი პაროლი',
                        minLength: {
                            value: 8,
                            message: 'პაროლი უნდა შეიცავდეს მინიმუმ 8 სიმბოლოს'
                        }
                    }}
                    render={({ field }) => (
                        <Input
                            {...field}
                            label="შეიყვანეთ ახალი პაროლი"
                            type="password"
                            placeholder="შეიყვანეთ ახალი პაროლი"
                        />
                    )}
                />
                {errors.newPassword && <span className="text-red-700 text-sm mt-2">{errors.newPassword.message}</span>}

                <div>
                    <button className="btn btn-block btn-primary" type='submit'>
                        შეცვლა
                    </button>
                </div>
            </form>
        </div>
    );
};

export default UpdatePassword;
