
import { useForm, Controller } from 'react-hook-form';
import Input from '../formComponents/Input';
import { yupResolver } from '@hookform/resolvers/yup';
import objectSchema from './schema/objectSchema';
import UploadFile from '../../UploadFile';
import { useDispatch } from 'react-redux';
import { fetchObjectRegister } from '../../../redux/thunks/authThunks';
import { useNavigate } from 'react-router-dom';
import { removeFile } from '../../../utils/removeFile';
import toast from 'react-hot-toast';

const ObjRegisterForm = () => {
    const
        { handleSubmit,
            formState: { errors },
            setValue,
            control,
            watch
        } = useForm({ resolver: yupResolver(objectSchema) });
    const dispatch = useDispatch();
    const navigate = useNavigate()

    //Get Image

    //Get option Type
    // const getOptionType = async () => {
    //     try {
    //         const res = await objectOptionType();
    //         setOptionType(res);
    //     } catch (e) {
    //         console.log(e);
    //     }
    // };

    // useEffect(() => {
    //     getOptionType();
    // }, []);

    const optionType = [
        "კვება და განთავსება",
        "ტურისტული პროდუქტი",
        "ტურისტული სერვისი",
        "ატრაქცია",
        "განთავსება",
        "კვება"
    ]


    const handleSetImages = (val) => {
        let currentImages = watch('images') || [];
        setValue('images', [...currentImages, val]);
    }

    const handleRemoveImage = (index) => {
        let currentImages = watch('images') || [];
        const newImages = currentImages?.filter((_, i) => i !== index);
        setValue('images', newImages);
    }

    const onSubmit = async (val) => {
        if(!val.facebook && !val.instagram) return toast.error("შეავსეთ რომელიმე სოციალური ქსელი")
        const { rePassword, ...rest } = val;
        
        const data = await dispatch(fetchObjectRegister(rest));

        if (!data.payload) {
            // setLoading(false)
        } else {
            if ('token' in data.payload) {
                window.localStorage.setItem('token', data.payload.token);
                navigate("/object-dashboard")
            } else {

            }
            // setLoading(false);
        }
        // const idNumber = generateRandom11Digits();
        // const formData = new FormData();


        // if (images) {
        //     images.forEach(element => {
        //         formData.append('uploaded_images', element);
        //     });
        // }

        // formData.append('id_number', idNumber);
        // formData.append('object_name', data.object_name);
        // formData.append('object_type', data.object_type);
        // formData.append('name', data.name);
        // formData.append('facebook', data.facebook);
        // formData.append('instagram', data.instagram);
        // formData.append('last_name', data.last_name);
        // formData.append('address', data.address);
        // formData.append('mobile', data.mobile);
        // formData.append('time_from', data.time_from);
        // formData.append('time_to', data.time_to);
        // formData.append('discount', data.discount.toString());
        // formData.append('email', data.email);
        // formData.append('password', data.password);
        // formData.append('description', data.description);

        // try {
        //     const response = await postEditUserData(formData);
        //     setEditStatus(response.message);
        //     toast('success', 'წარმატებით შეიცვალა ინფორმაცია');
        //     setTimeout(() => {
        //         router.replace('/dashboard');
        //     }, 1000);
        // } catch (error) {
        //     console.error('Error editing user:', error);
        //     setEditStatus('Error editing user');
        // }
    };

    return (
        <div>
            <form className='w-full space-y-4 my-4' onSubmit={handleSubmit(onSubmit)}>
                {/* Personal Information */}
                <h3 className='flex items-center'>
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth='1.5'
                        stroke='currentColor'
                        className='w-6 h-6 mr-2'
                    >
                        <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            d='M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z'
                        />
                    </svg>
                    <strong>პირადი ინფორმაცია</strong>
                </h3>
                <div className='flex flex-col md:flex-row gap-4'>
                    <div className='w-full'>
                        <Controller
                            name='name'
                            control={control}
                            rules={{ required: 'შეიყვანეთ: სახელი' }}
                            render={({ field }) => (
                                <Input
                                    label='სახელი'
                                    type='text'
                                    id='name'
                                    placeholder='მაგ: გიორგი'
                                    className='w-full input input-bordered mt-2 text-md text-gray-500'
                                    {...field}
                                />
                            )}
                        />
                        {errors.name && (
                            <span className='text-red-700 text-sm mt-2'>{errors.name.message}</span>
                        )}
                    </div>
                    <div className='w-full'>
                        <Controller
                            name='last_name'
                            control={control}
                            rules={{ required: 'შეიყვანეთ: გვარი' }}
                            render={({ field }) => (
                                <Input
                                    label='გვარი'
                                    type='text'
                                    id='last_name'
                                    placeholder='მაგ: ბერიძე'
                                    className='w-full input input-bordered mt-2 text-md text-gray-500'
                                    {...field}
                                />
                            )}
                        />
                        {errors.last_name && (
                            <span className='text-red-700 text-sm mt-2'>
                                {errors.last_name.message}
                            </span>
                        )}
                    </div>
                </div>
                <div className='flex flex-col md:flex-row gap-4'>
                    <div className='w-full'>
                        <Controller
                            name='mobile'
                            control={control}
                            rules={{ required: 'შეიყვანეთ: მობილური' }}
                            render={({ field }) => (
                                <Input
                                    label='მობილური'
                                    type='tel'
                                    id='mobile'
                                    className='w-full input input-bordered mt-2 text-md text-gray-500'
                                    placeholder='მაგ: 555112233'
                                    {...field}
                                />
                            )}
                        />
                        {errors.mobile && (
                            <span className='text-red-700 text-sm mt-2'>{errors.mobile.message}</span>
                        )}
                    </div>
                    <div className='w-full'>
                        <Controller
                            name='email'
                            control={control}
                            rules={{ required: 'შეიყვანეთ: მეილი' }}
                            render={({ field }) => (
                                <Input
                                    label='მეილი'
                                    type='text'
                                    id='email'
                                    className='w-full input input-bordered mt-2 text-md text-gray-500'
                                    placeholder='მაგ: giorgiberidze@mail.com'
                                    {...field}
                                />
                            )}
                        />
                        {errors.email && (
                            <span className='text-red-700 text-sm mt-2'>{errors.email.message}</span>
                        )}
                    </div>
                </div>
                <div className='flex flex-col md:flex-row gap-4'>
                    <div className='w-full'>
                        <Controller
                            name='password'
                            control={control}
                            rules={{ required: 'შეიყვანეთ: პაროლი' }}
                            render={({ field }) => (
                                <Input
                                    label='პაროლი'
                                    type='password'
                                    id='password'
                                    className='w-full input input-bordered mt-2 text-md text-gray-500'
                                    placeholder='მინ. 8 სიმბოლო'
                                    {...field}
                                />
                            )}
                        />
                        {errors.mobile && (
                            <span className='text-red-700 text-sm mt-2'>{errors.password.message}</span>
                        )}
                    </div>
                    <div className='w-full'>
                        <Controller
                            name='rePassword'
                            control={control}
                            rules={{ required: 'გაიმეორეთ პაროლი' }}
                            render={({ field }) => (
                                <Input
                                    label='გაიმეორეთ პაროლი'
                                    type='password'
                                    id='rePassword'
                                    className='w-full input input-bordered mt-2 text-md text-gray-500'
                                    placeholder='გაიმეორეთ:'
                                    {...field}
                                />
                            )}
                        />
                        {errors.email && (
                            <span className='text-red-700 text-sm mt-2'>{errors.rePassword.message}</span>
                        )}
                    </div>
                </div>


                {/* Object Information */}
                <h3 className='flex items-center'>
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth='1.5'
                        stroke='currentColor'
                        className='w-6 h-6 mr-2'
                    >
                        <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            d='M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z'
                        />
                    </svg>
                    <strong>ობიექტის ინფორმაცია</strong>
                </h3>
                <div className='flex flex-col md:flex-row gap-4'>
                    <div className='w-full'>
                        <Controller
                            name='object_name'
                            control={control}
                            rules={{ required: 'შეიყვანეთ: ობიექტის სახელი' }}
                            render={({ field }) => (
                                <Input
                                    label='ობიექტის სახელი'
                                    type='text'
                                    id='object_name'
                                    className='w-full input input-bordered mt-2 text-md text-gray-500'
                                    placeholder='მაგ: მანქანის სალონი'
                                    {...field}
                                />
                            )}
                        />
                        {errors.object_name && (
                            <span className='text-red-700 text-sm mt-2'>
                                {errors.object_name.message}
                            </span>
                        )}
                    </div>
                    <div className='w-full flex flex-col'>
                        <label htmlFor="object_type">ობიექტის ტიპი</label>
                        <Controller
                            name='object_type'
                            control={control}
                            rules={{ required: 'შეიყვანეთ: ობიექტის ტიპი' }}
                            render={({ field }) => (
                                <select
                                    {...field}
                                    className='w-full input input-bordered mt-2 text-md text-gray-500'
                                    id='object_type'
                                >
                                    <option disabled value=''>
                                        აირჩიეთ ობიექტის ტიპი
                                    </option>
                                    {optionType.map((type, index) => (
                                        <option key={index} value={type}>
                                            {type}
                                        </option>
                                    ))}
                                </select>
                            )}
                        />
                        {errors.object_type && (
                            <span className='text-red-700 text-sm mt-2'>
                                {errors.object_type.message}
                            </span>
                        )}
                    </div>
                </div>

                {/* Social Media */}
                <h3 className='flex items-center'>
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth='1.5'
                        stroke='currentColor'
                        className='w-6 h-6 mr-2'
                    >
                        <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            d='M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z'
                        />
                    </svg>
                    <strong>სოციალური მედია</strong>
                </h3>
                <div className='flex flex-col md:flex-row gap-4'>
                    <div className='w-full'>
                        <Controller
                            name='facebook'
                            control={control}
                            render={({ field }) => (
                                <Input
                                    label='Facebook'
                                    type='text'
                                    id='facebook'
                                    className='w-full input input-bordered mt-2 text-md text-gray-500'
                                    placeholder='Facebook URL'
                                    {...field}
                                />
                            )}
                        />
                    </div>
                    <div className='w-full'>
                        <Controller
                            name='instagram'
                            control={control}
                            render={({ field }) => (
                                <Input
                                    label='Instagram'
                                    type='text'
                                    id='instagram'
                                    className='w-full input input-bordered mt-2 text-md text-gray-500'
                                    placeholder='Instagram URL'
                                    {...field}
                                />
                            )}
                        />
                    </div>
                </div>

                {/* Address and Description */}
                <h3 className='flex items-center'>
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth='1.5'
                        stroke='currentColor'
                        className='w-6 h-6 mr-2'
                    >
                        <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            d='M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z'
                        />
                    </svg>
                    <strong>მისამართი და აღწერა</strong>
                </h3>
                <div className='flex flex-col md:flex-row gap-4'>
                    <div className='w-full'>
                        <Controller
                            name='address'
                            control={control}
                            render={({ field }) => (
                                <Input
                                    label='მისამართი'
                                    type='text'
                                    id='address'
                                    className='w-full input input-bordered mt-2 text-md text-gray-500'
                                    placeholder='მაგ: წერეთლის #44'
                                    {...field}
                                />
                            )}
                        />
                    </div>
                    <div className='w-full'>
                        <Controller
                            name='discount'
                            control={control}
                            render={({ field }) => (
                                <Input
                                    label='ფასდაკლება'
                                    type='text'
                                    id='discount'
                                    className='w-full input input-bordered mt-2 text-md text-gray-500'
                                    placeholder='მაგ: 25%'
                                    {...field}
                                />
                            )}
                        />
                        {errors.discount && (
                            <span className='text-red-700 text-sm mt-2'>
                                {errors.discount.message}
                            </span>
                        )}
                    </div>
                </div>

                {/* Working Hours and Discount */}
                <h3 className='flex items-center'>
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth='1.5'
                        stroke='currentColor'
                        className='w-6 h-6 mr-2'
                    >
                        <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            d='M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z'
                        />
                    </svg>
                    <strong>სამუშაო საათები და ფასდაკლება</strong>
                </h3>
                <div className='flex flex-col md:flex-row gap-4'>
                    <div className='w-full'>
                        <Controller
                            name='time_from'
                            control={control}
                            render={({ field }) => (
                                <Input
                                    label='სამუშაო საათების დაწყება'
                                    type='time'
                                    id='time_from'
                                    className='w-full input input-bordered mt-2 text-md text-gray-500'
                                    placeholder='მაგ: ორშაბათი - შაბათი, 10:00 - 18:00'
                                    {...field}
                                />
                            )}
                        />
                    </div>
                    <div className='w-full'>
                        <Controller
                            name='time_to'
                            control={control}
                            render={({ field }) => (
                                <Input
                                    label='სამუშაო საათების დასასრული'
                                    type='time'
                                    id='time_to'
                                    className='w-full input input-bordered mt-2 text-md text-gray-500'
                                    placeholder='მაგ: ორშაბათი - შაბათი, 10:00 - 18:00'
                                    {...field}
                                />
                            )}
                        />
                    </div>

                </div>
                <div className='flex flex-col md:flex-row gap-4'>
                    <div className='w-full'>
                        <div className='w-full'>
                            <Controller
                                name='description'
                                control={control}
                                render={({ field }) => (
                                    <Input
                                        label='მოკლე აღწერა'
                                        type='text'
                                        id='description'
                                        className='w-full input input-bordered mt-2 text-md text-gray-500'
                                        placeholder='მაგ: ჩემი სალონი'
                                        {...field}
                                    />
                                )}
                            />
                            {errors.description && (
                                <span className='text-red-700 text-sm mt-2'>
                                    {errors.description.message}
                                </span>
                            )}
                        </div>
                    </div>
                </div>
                <div className='flex flex-col md:flex-row gap-4'>
                    <div className='w-full'>
                        <label htmlFor="">სურათი</label>
                        <UploadFile dir={'objects'} setState={(val) => handleSetImages(val)}>
                            <div className='w-full h-[70px] cursor-pointer border-2 border-dashed flex items-center justify-center'>
                                მაქს. 2 მეგაბაიტი
                            </div>
                        </UploadFile>
                        {watch('images') && watch('images').map((image, _id) => <p key={_id}>{image} <button className='px-2 py-0 rounded-lg my-1 bg-blue-600' type='button' onClick={() => {
                            removeFile({url: image, reset: () => handleRemoveImage(_id)})
                        }}>X</button></p>)}
                        {errors.images && (
                            <span className='text-red-700 text-sm mt-2'>
                                {errors.images.message}
                            </span>
                        )}
                    </div>
                </div>

                {/* Button */}
                <div className='flex justify-end mt-4'>
                    <button
                        type='submit'
                        className='btn btn-primary flex items-center space-x-2'
                    >
                        <span>რეგისტრაცია</span>
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='none'
                            viewBox='0 0 24 24'
                            stroke='currentColor'
                            className='w-5 h-5'
                        >
                            <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                strokeWidth='2'
                                d='M5 13l4 4L19 7'
                            />
                        </svg>
                    </button>
                </div>
            </form>
        </div>
    );
};

export default ObjRegisterForm;