import { useSelector } from "react-redux";

export default function useCurrentUser() {
  const authStatus = useSelector((state) => state.auth);

  return {
    user: authStatus?.data,
    status: authStatus?.status,
  }
}
