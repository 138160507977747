import Input from "../../../Forms/formComponents/Input"
import { Controller, useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import { fetchParticipantEdit } from "../../../../redux/thunks/authThunks"
import toast from "react-hot-toast"
import UploadFile from "../../../UploadFile"

const EditDetails = ({ defaults }) => {
    // const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const {
        control,
        handleSubmit,
        formState: { errors, isDirty },
        setValue
    } = useForm({
        defaultValues: {
            ...defaults
        }
    })

    const onSubmit = async (values) => {
        if(!values.fb && !values.tk && !values.yt && !values.ig) return toast.error("შეავსეთ რომელიმე სოციალური ქსელი")
        const { id, personalNumber, tour, verificationCode, verified, _v, _id, updated_at, stage, screens, role, isWinner, created_at, ...rest } = values
        try {
            dispatch(fetchParticipantEdit(rest));
            toast.success('ინფორმაცია წარმატებით შეიცვალა');
        } catch (err) {
            toast.error('დაფიქსირდა შეცდომა ' + err.response?.data?.message);
        }
        // try {
        //     setError('')
        //     setLoading(true)
        //     const updates = {
        //         id,
        //         name,
        //         lastname,
        //         tel,
        //         birthday,
        //         fb,
        //         ig,
        //         tk,
        //         yt,
        //         updated_at: new Date(),
        //     }

        //     let { error } = await supabase.from('profiles').upsert(updates)

        //     if (error) {
        //         setError(error?.message)
        //     }

        //     if (onSub) onSub()
        // } catch (error) {
        //     console.error(error);
        //     setError('An error occurred while updating the profile.')
        // } finally {
        //     setLoading(false)
        // }
    }

    return (
        <>
            <form className='space-y-6 my-6 w-full' onSubmit={handleSubmit(onSubmit)}>

                <div className="flex flex-col lg:flex-row items-center justify-between gap-6 mt-4">

                    <Controller
                        name="avatar_url"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => <UploadFile dir={'participants'} setState={(value) => setValue('avatar_url', value)} className="h-[280px] w-full lg:w-[35%] flex file-input file-input-bordered p-0">
                            <img className="w-full object-cover" src={`${process.env.REACT_APP_EXPRESS_URL}${field.value}`} alt="profile" />
                        </UploadFile>}
                    />



                    <div className="flex flex-col md:block space-y-5 w-full">
                        <div className="space-y-5 w-full">
                            <div className="flex flex-col md:flex-row gap-4">
                                <div className="w-full">
                                    <Controller
                                        name="name"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field }) => <Input {...field} label="სახელი" placeholder="სახელი" />}
                                    /> {errors.name && <span className="text-red-700 mt-2">* აუცილებელი ველი</span>}
                                </div>
                                <div className="w-full">
                                    <Controller
                                        name="lastname"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field }) => <Input {...field} label="გვარი" placeholder="გვარი" />}
                                    /> {errors.lastname && <span className="text-red-700 mt-2">* აუცილებელი ველი</span>}
                                </div>
                            </div>
                        </div>

                        <div className="space-y-5 w-full">
                            <div className="flex flex-col md:flex-row gap-4">
                                <div className="w-full">
                                    <Controller
                                        name="tel"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field }) => <Input {...field} type="tel" pattern="[0-9]{3}[0-9]{2}[0-9]{2}[0-9]{2}" label="ტელეფონი" placeholder="511-12-34-56" />}
                                    /> {errors.tel && <span className="text-red-700 mt-2">* აუცილებელი ველი</span>}
                                </div>
                                <div className="w-full">
                                    <Controller
                                        name="birthday"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field }) => <Input {...field} type="date" label="დაბადების თარიღი" placeholder="დაბადების თარიღი" />}
                                    /> {errors.birthday && <span className="text-red-700 mt-2">* აუცილებელი ველი</span>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Controller
                    name="fb"
                    control={control}
                    render={({ field }) => <Input {...field} type="text" label="Facebook" placeholder="https://www.facebook.com/*********/" />}
                /> {errors.fb && <span className="text-red-700 mt-2">* აუცილებელი ველი</span>}
                <Controller
                    name="ig"
                    control={control}
                    render={({ field }) => <Input {...field} type="text" label="Instagram" placeholder="https://www.instagram.com/*********/" />}
                /> {errors.ig && <span className="text-red-700 mt-2">* აუცილებელი ველი</span>}
                <Controller
                    name="yt"
                    control={control}
                    render={({ field }) => <Input {...field} type="text" label="Youtube" placeholder="https://www.youtube.com/@*********/" />}
                /> {errors.yt && <span className="text-red-700 mt-2">* აუცილებელი ველი</span>}
                <Controller
                    name="tk"
                    control={control}
                    render={({ field }) => <Input {...field} type="text" label="Tiktok" placeholder="https://www.tiktok.com/@*********/" />}
                /> {errors.tk && <span className="text-red-700 mt-2">* აუცილებელი ველი</span>}

                <div className="mt-5">
                    <button className="btn btn-block btn-primary" type='submit' disabled={!isDirty}>
                        {/* {loading && <span className="loading loading-spinner"></span>} */}
                        განახლება</button>
                </div>
            </form>
        </>
    )
}

export default EditDetails
