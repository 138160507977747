import LoginForm from "../../components/Forms/LoginForm"

export default function Login({off}) {
    
    if(off){
        return (
            <div className="w-full md:w-2/3 mx-auto p-6 h-[80dvh]">
                <div className="card w-full lg:w-1/2 bg-base-100 shadow-xl mx-auto mt-6">
                    <div className="card-body text-center">
                        რეგისტრაცია დაიწყება 00:00 საათიდან
                    </div>
                </div>
            </div>
        )
    } 
    
    return (
        <div className="w-full md:w-2/3 mx-auto p-6 h-[80dvh]">
            <div className="card w-full lg:w-1/2 bg-base-100 shadow-xl mx-auto mt-6">
                <div className="card-body">
                    <LoginForm />
                </div>
            </div>
        </div>
    )
}