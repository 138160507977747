import React, { useState } from 'react';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

const ExportToZip = ({ jsonData, className }) => {
    const [loading, setLoading] = useState(false);
    const handleExport = async () => {
        setLoading(true)
        const zip = new JSZip();

        // Iterate over each screen data
        for (const screen of jsonData) {
            const stageFolderName = `stage_${screen.stage}`;
            const stageFolder = zip.folder(stageFolderName);

            try {
                const url = `https://qncbnxbxcvvacstnmmdk.supabase.co/storage/v1/object/public/screens/${screen.image}`;
                console.log('Fetching URL:', url); // Debugging URL

                const screenResponse = await fetch(url);
                if (!screenResponse.ok) {
                    throw new Error(`Failed to fetch screen ${screen.image} - Status: ${screenResponse.status}`);
                }
                const screenBlob = await screenResponse.blob();
                const imageName = screen.image.split('/').pop().replace('.', `___${screen.views} views.`);
                stageFolder.file(imageName, screenBlob);
            } catch (error) {
                console.error(`Error fetching screen ${screen.image}:`, error);
            }
        }

        // Generate and save the ZIP file
        zip.generateAsync({ type: 'blob' }).then((content) => {
            saveAs(content, 'screens.zip');
            setLoading(false)
        }).catch(() => {
            setLoading(false)
        });
    };

    return (
        <button disabled={loading} onClick={handleExport} className={`btn min-w-full bg-purple-500 text-white ${className}`}>
            {!loading ? ".zip ფაილში დაექსპორტება" : "loading..."}
        </button>
    );
};

export default ExportToZip;