import React, { useEffect, useState } from 'react';
import UserCard from '../../components/Participants/UserCard';
import { useDispatch, useSelector } from "react-redux";
import { getAllArchivedParticipants, getAllParticipants } from '../../redux/thunks/participants';
import supabase from '../../libs/supabase';
import axios from "../../libs/axios"
import toast from 'react-hot-toast';
import { getYearOnly } from '../../utils/getYearOnly';

const PastWinnersIds = [
    'd3e9966c-59f2-4aed-bcfc-9bcd4c86b2ab',
    '0af9a955-208c-4a9e-975e-d9a759a79006',
    'ce19da05-95e0-4234-ac8a-471cebd631c3',
    '8d7b572f-eb26-4758-af3c-7cef9caa5ae6',
    'fc15242e-4932-4afb-97f8-0da92ddc7316',
    '567df4ca-805c-4fc5-9253-90431f99fa83'
];

const Participants = () => {
    const [users, setUsers] = useState(null);
    const [selectedYear, setSelectedYear] = useState(0);
    const [years, setYears] = useState([]);
    const dispatch = useDispatch();
    const participants = useSelector((state) => state.participants);

    useEffect(() => {
        const fetchPastYearsParticipants = async () => {
            const { data, error } = await supabase
                .from('profiles')
                .select("*")
                .eq('active', true)
                .order("created_at", { ascending: false });

            if (error) {
                console.error('Error fetching winners:', error);
            } else {
                // Separate winners from other users
                const winners = data.filter(user => PastWinnersIds.includes(user.id));
                const otherUsers = data.filter(user => !PastWinnersIds.includes(user.id));

                // Sort winners according to PastWinnersIds order
                winners.sort((a, b) => PastWinnersIds.indexOf(a.id) - PastWinnersIds.indexOf(b.id));

                setUsers([...winners, ...otherUsers]);
            }
        };

        if (selectedYear === 2023) {
            fetchPastYearsParticipants();
        }
    }, [selectedYear]);

    useEffect(() => {
        axios.get('/archive')
            .then(res => setYears(res.data))
            .catch(err => toast.error(err.resposne?.data?.message))
    }, [])

    useEffect(() => {
        if (selectedYear === 0) {
            dispatch(getAllParticipants({ hidden: false }))
        } else {
            dispatch(getAllArchivedParticipants({ date: selectedYear }))
        }
    }, [dispatch, selectedYear])

    const handleYearChange = (year) => {
        setSelectedYear(year);
    };


    return (
        <div className="container mx-auto my-4 px-7">
            <div className="flex justify-center space-x-4 mb-4">
                <button
                    className={`btn ${selectedYear === 0 ? 'btn-primary' : 'btn-neutral'}`}
                    onClick={() => handleYearChange(0)}
                >
                    ახლა
                </button>
                {years?.map((year, _index) => {
                    if(getYearOnly(year.date) === 2023) return null;
                    return (
                        <button
                            key={_index}
                            className={`btn ${selectedYear === getYearOnly(year.date) ? 'btn-primary' : 'btn-neutral'}`}
                            onClick={() => handleYearChange(getYearOnly(year.date))}
                        >
                            {getYearOnly(year.date)}
                        </button>
                    )
                })}
                <button
                    className={`btn ${selectedYear === 2023 ? 'btn-primary' : 'btn-neutral'}`}
                    onClick={() => handleYearChange(2023)}
                >
                    2023
                </button>

            </div>
            <div className="mt-5 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                {selectedYear === 2023
                    ? (
                        users?.map((user, i) => (
                            <UserCard
                                {...user}
                                avatar_url={`${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/profiles/${user?.avatar_url}.webp`}
                                key={i}
                                isWinner={i <= 5 && true}
                                place={i <= 5 && i + 1}
                            />
                        ))
                    )
                    : (
                        participants.data?.map((user, i) => (
                            <UserCard {...user} isNew={true} avatar_url={`${process.env.REACT_APP_EXPRESS_URL}${user.avatar_url}`} key={i} />
                        ))
                    )
                }
            </div>
        </div>
    );
};

export default Participants;
