import SocialReachesSingle from "./SocialReachesSingle";

const SocialsStatistics = ({ userId, hasReached }) => {


    
    return (
        <>
            <SocialReachesSingle social="facebook" title="Facebook" userId={userId} hasReached={hasReached} />
            <div className="divider"></div>

            <SocialReachesSingle social="instagram" title="Instagram" userId={userId} hasReached={hasReached} />
            <div className="divider"></div>

            <SocialReachesSingle social="youtube" title="Youtube" userId={userId} hasReached={hasReached} />
            <div className="divider"></div>

            <SocialReachesSingle social="tiktok" title="tiktok" userId={userId} hasReached={hasReached} />
        </>
    );
};

export default SocialsStatistics;
