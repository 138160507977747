import React from 'react';
import Loader from '../Loader';

const Hero = ({ content, video_url, isLoading }) => {
    // Replace placeholder content with the actual content
    return (
        <section className="relative h-80 md:h-screen mb-20 flex flex-col items-center justify-center text-center text-white py-0 px-3">
            <div className="video-docker absolute top-0 left-0 w-full h-full overflow-hidden">
                {(!isLoading && video_url) ? (
                    <video
                        className="min-w-full min-h-full absolute object-cover"
                        src={process.env.REACT_APP_EXPRESS_URL + video_url}
                        autoPlay
                        muted
                        loop
                    />
                ) : (
                    <p>loading..</p>
                )}
            </div>
            <div className="video-content space-y-6 sm:space-y-8 relative z-10">
                <div className='flex justify-center items-start'>
                    <span className='text-3xl lg:text-7xl lg:-mt-1'>#</span>
                    <p className="text-3xl lg:text-7xl tracking-widest font-geo-gza leading-10">gamomyeviaWaraSi <br /> da moige <br /> 100000 ₾ </p>
                </div>
            </div>
        </section>
    );
};

export default Hero;
